import { useEffect, useState } from "react"
import Loader from "../../utilities/Loader/Loader"
import axiosInstance from "../../../hooks/axiosInstance"
import { toast } from "react-toastify"

export default function Socials({socialDetails, fetchUserProfile}) {
    const [formData, setFormData] = useState({
        'facebook_url': '',
        'twitter_url': '',
        'instagram_url': ''
    })
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target

        setFormData(prevFormData => (
            { ...prevFormData, [name]: value }
        ))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        axiosInstance.post('/user/profile/socials', formData)
            .then(res => {
                toast.success(res.data.message)
                fetchUserProfile()
            })
            .catch(err => {
                toast.error('An error occured. Please, try again')
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        setFormData({
          'facebook_url': socialDetails?.facebook_url,
          'twitter_url': socialDetails?.twitter_url,
          'instagram_url': socialDetails?.instagram_url
        })
    }, [socialDetails])

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3 d-flex">
                <div className="avatar-xs d-block flex-shrink-0 me-3">
                    <span className="avatar-title rounded-circle fs-16 bg-primary">
                        <i className="ri-facebook-fill"></i>
                    </span>
                </div>
                <input
                    type="text"
                    className="form-control"
                    id="fbUsername"
                    placeholder="Facebook"
                    name="facebook_url"
                    value={formData.facebook_url}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3 d-flex">
                <div className="avatar-xs d-block flex-shrink-0 me-3">
                    <span className="avatar-title rounded-circle fs-16 bg-success">
                        <i className="ri-twitter-fill"></i>
                    </span>
                </div>
                <input
                    type="text"
                    className="form-control"
                    id="websiteInput"
                    placeholder="Twitter"
                    name="twitter_url"
                    value={formData.twitter_url}
                    onChange={handleChange}
                />
            </div>
            <div className="mb-3 d-flex">
                <div className="avatar-xs d-block flex-shrink-0 me-3">
                    <span className="avatar-title rounded-circle fs-16 bg-danger">
                        <i className="ri-instagram-fill"></i>
                    </span>
                </div>
                <input
                    type="text"
                    className="form-control"
                    id="dribbleName"
                    placeholder="Instagram"
                    name="instagram_url"
                    value={formData.instagram_url}
                    onChange={handleChange}
                />
            </div>
            <div >
                <button type="submit" className="btn btn-success w-100">
                    Update
                </button>
            </div>
            {loading && <Loader />}
        </form>
    )
}