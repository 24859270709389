import { Link, NavLink } from "react-router-dom"

export default function AssociationAdminApplicationsPage() {
    return (
        <>
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Applications</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/app">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Applications</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row" id="contactList">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex align-items-center border-0">
                            <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                        <i className="fas fa-home"></i> Open Applications
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                        <i className="far fa-user"></i> Closed Applications
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div className="tab-content">
                                <div className="tab-pane active" id="personalDetails" role="tabpanel">
                                    <div className="border mb-4 border-dashed pb-3 border-end-0 border-start-0">
                                        <div className="row g-2">
                                            <div className="col-xl-3 col-md-6">
                                                <div className="search-box">
                                                    <input type="text" className="form-control search" placeholder="Search applicants..." />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-md-4">
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon2">From</span>
                                                    <input type="date" className="form-control" placeholder="Start Date" />
                                                </div>
                                            </div>
                                            {/* <!--end col--> */}
                                            <div className="col-xl-2 col-md-4">
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon2">To</span>
                                                    <input type="date" className="form-control" placeholder="Start Date" />
                                                </div>
                                            </div>
                                            {/* <!--end col--> */}
                                            {/* <div className="col-xl-3 col-md-6">
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><i className="ri-calendar-2-line"></i></span>
                                                    <input type="text" className="form-control" data-date-format="d M, Y" data-range-date="true" placeholder="Select date" id="range-datepicker" aria-describedby="basic-addon1" />
                                                </div>
                                            </div> */}
                                            {/* <!--end col--> */}
                                            {/* <div className="col-xl-2 col-md-4">
                                                <select className="form-control" name="idType" id="idType">
                                                    <option value="all">Select Type</option>
                                                    <option value="Buy">Free</option>
                                                    <option value="Sell">Paid</option>
                                                </select>
                                            </div> */}
                                            {/* <!--end col--> */}
                                            {/* <div className="col-xl-2 col-md-4">
                                                <select className="form-control" name="idStatus" id="idStatus">
                                                    <option value="all">Select Status</option>
                                                    <option value="Successful">Active</option>
                                                    <option value="Cancelled">Inactive</option>
                                                </select>
                                            </div> */}
                                            {/* <div className="col-xl-2 col-md-4">
                                                <select className="form-control" name="idStatus" id="idStatus">
                                                    <option value="all">Select Membership Status</option>
                                                    <option value="Successful">Active</option>
                                                    <option value="Cancelled">Expired</option>
                                                </select>
                                            </div> */}
                                            {/* <!--end col--> */}
                                            <div className="col-xl-1 col-md-4">
                                                <button className="btn btn-success w-100" onclick="filterData();">Filters</button>
                                            </div>
                                        </div>
                                        {/* <!--end row--> */}
                                    </div>
                                    <div className="table-responsive table-card">
                                        <table
                                            className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                            <thead className="text-muted table-light">
                                                <tr>
                                                    <th scope="col">S/N</th>
                                                    <th scope="col">Applicant Info</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">1</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-1.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Alex Smith</div>
                                                        </div>
                                                    </td>
                                                    <td>Jan 10, 2023</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-warning-subtle text-warning">Pending</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Reject</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <!-- end tr --> */}
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">2</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-2.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Jansh Brown</div>
                                                        </div>
                                                    </td>
                                                    <td>Oct 3, 2022</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-warning-subtle text-warning">Pending</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Reject</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <!-- end tr --> */}
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">3</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-3.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Ayaan Bowen</div>
                                                        </div>
                                                    </td>
                                                    <td>Jul  13, 2023</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-warning-subtle text-warning">Pending</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Reject</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <!-- end tr --> */}
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">4</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-4.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Prezy Mark</div>
                                                        </div>
                                                    </td>
                                                    <td>Jan 11, 2021</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-warning-subtle text-warning">Pending</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Reject</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <!-- end tr --> */}
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">5</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-6.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Vihan Hudda</div>
                                                        </div>
                                                    </td>
                                                    <td>Apr 07, 2023</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-warning-subtle text-warning">Pending</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Reject</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end tab-pane--> */}
                                <div className="tab-pane" id="changePassword" role="tabpanel">
                                    <div className="border mb-4 border-dashed pb-3 border-end-0 border-start-0">
                                        <div className="row g-2">
                                            <div className="col-xl-3 col-md-6">
                                                <div className="search-box">
                                                    <input type="text" className="form-control search" placeholder="Search applicants..." />
                                                    <i className="ri-search-line search-icon"></i>
                                                </div>
                                            </div>
                                            <div className="col-xl-2 col-md-4">
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon2">From</span>
                                                    <input type="date" className="form-control" placeholder="Start Date" />
                                                </div>
                                            </div>
                                            {/* <!--end col--> */}
                                            <div className="col-xl-2 col-md-4">
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon2">To</span>
                                                    <input type="date" className="form-control" placeholder="Start Date" />
                                                </div>
                                            </div>
                                            {/* <!--end col--> */}
                                            {/* <div className="col-xl-3 col-md-6">
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><i className="ri-calendar-2-line"></i></span>
                                                    <input type="text" className="form-control" data-date-format="d M, Y" data-range-date="true" placeholder="Select date" id="range-datepicker" aria-describedby="basic-addon1" />
                                                </div>
                                            </div> */}
                                            {/* <!--end col--> */}
                                            {/* <div className="col-xl-2 col-md-4">
                                                <select className="form-control" name="idType" id="idType">
                                                    <option value="all">Select Type</option>
                                                    <option value="Buy">Free</option>
                                                    <option value="Sell">Paid</option>
                                                </select>
                                            </div> */}
                                            {/* <!--end col--> */}
                                            {/* <div className="col-xl-2 col-md-4">
                                                <select className="form-control" name="idStatus" id="idStatus">
                                                    <option value="all">Select Status</option>
                                                    <option value="Successful">Active</option>
                                                    <option value="Cancelled">Inactive</option>
                                                </select>
                                            </div> */}
                                            <div className="col-xl-2 col-md-4">
                                                <select className="form-control" name="idStatus" id="idStatus">
                                                    <option value="all">Select Status</option>
                                                    <option value="Successful">Approved</option>
                                                    <option value="Cancelled">Rejected</option>
                                                    {/* <option value="Pending">Pending</option> */}
                                                </select>
                                            </div>
                                            {/* <!--end col--> */}
                                            <div className="col-xl-1 col-md-4">
                                                <button className="btn btn-success w-100" onclick="filterData();">Filters</button>
                                            </div>
                                        </div>
                                        {/* <!--end row--> */}
                                    </div>
                                    <div className="table-responsive table-card">
                                        <table
                                            className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                            <thead className="text-muted table-light">
                                                <tr>
                                                    <th scope="col">S/N</th>
                                                    <th scope="col">Applicant Info</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">1</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-1.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Alex Smith</div>
                                                        </div>
                                                    </td>
                                                    <td>Jan 10, 2023</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-success-subtle text-success">Approved</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <!-- end tr --> */}
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">2</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-2.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Jansh Brown</div>
                                                        </div>
                                                    </td>
                                                    <td>Oct 3, 2022</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-danger-subtle text-danger">Rejected</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <!-- end tr --> */}
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">3</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-3.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Ayaan Bowen</div>
                                                        </div>
                                                    </td>
                                                    <td>Jul  13, 2023</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-danger-subtle text-danger">Rejected</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <!-- end tr --> */}
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">4</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-4.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Prezy Mark</div>
                                                        </div>
                                                    </td>
                                                    <td>Jan 11, 2021</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-success-subtle text-success">Approved</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                                {/* <!-- end tr --> */}
                                                <tr>
                                                    <td>
                                                        <a href="apps-ecommerce-order-details.html"
                                                            className="fw-medium link-primary">5</a>
                                                    </td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 me-2">
                                                                <img src="/assets/images/users/avatar-6.jpg"
                                                                    alt=""
                                                                    className="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div className="flex-grow-1">Vihan Hudda</div>
                                                        </div>
                                                    </td>
                                                    <td>Apr 07, 2023</td>
                                                    <td>
                                                        <span
                                                            className="badge bg-success-subtle text-success">Approved</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button className="btn btn-light btn-icon btn-sm fs-16 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="ri-equalizer-fill"></i>
                                                            </button>
                                                            <ul className="dropdown-menu">
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Approve</a></li>
                                                            </ul>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {/* <!--end tab-pane--> */}
                            </div>
                        </div>
                    </div>
                    {/* <!--end card--> */}
                </div>
                {/* <!--end col--> */}
            </div>
            {/* <!--end row--> */}
        </>
    )
}