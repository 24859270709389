import { useEffect, useState } from "react"
import axiosInstance from "../../../hooks/axiosInstance"
import { toast } from "react-toastify"
import Loader from "../../utilities/Loader/Loader"

export default function OrganizationDetail({organizationDetail, fetchUserProfile}) {
  const [formData, setFormData] = useState({
    'company_name': '',
    'sector': '',
    'company_address': '',
    'company_phone': '',
    'company_email': '',
    'nationality': '',
    'profession': '',
    'position': ''
  })
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prevFormData => (
      { ...prevFormData, [name]: value }
    ))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    axiosInstance.post('/user/profile/company', formData)
      .then(res => {
        toast.success(res.data.message)
        fetchUserProfile()
      })
      .catch(err => {
        toast.error('An error occured. Please, try again')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    setFormData({
      'company_name': organizationDetail?.name,
      'sector': organizationDetail?.sector,
      'company_address': organizationDetail?.address,
      'company_phone': organizationDetail?.phone,
      'company_email': organizationDetail?.email,
      'nationality': organizationDetail?.nationality,
      'profession': organizationDetail?.profession,
      'position': organizationDetail?.company_position
    })
  }, [organizationDetail])

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="company" className="form-label">
              Company Name
            </label>
            <input
              type="text"
              className="form-control"
              id="company"
              placeholder="Enter Company Name"
              name="company_name"
              value={formData.company_name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="sector" className="form-label">
              Sector
            </label>
            <input
              type="text"
              className="form-control"
              id="sector"
              placeholder="Enter Sector"
              name="sector"
              value={formData.sector}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="emailadress" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="emailadress"
              placeholder="Enter Company Email"
              name="company_email"
              value={formData.company_email}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}
        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="Profession" className="form-label">
              Profession
            </label>
            <input
              type="text"
              className="form-control"
              id="Profession"
              placeholder="Enter Profession"
              name="profession"
              value={formData.profession}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="position" className="form-label">
              Position in organization
            </label>
            <input
              type="text"
              className="form-control"
              id="position"
              placeholder="Position in organization"
              name="position"
              value={formData.position}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="phonenumberInput2" className="form-label">
              Phone Number
            </label>
            <input
              type="tel"
              className="form-control"
              id="phonenumberInput2"
              placeholder="Enter your Company Phone number"
              name="company_phone"
              value={formData.company_phone}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="col-lg-6">
          <div className="mb-3 pb-2">
            <label for="exampleFormControlTextarea" className="form-label">
              Company Address
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea"
              placeholder="Enter your description"
              rows="1"
              name="company_address"
              value={formData.company_address}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label for="nationality" className="form-label">
              Nationality
            </label>
            <input
              type="text"
              className="form-control"
              id="nationality"
              placeholder="Enter Nationality"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}

        <div className="col-lg-12">
          <div className="hstack gap-2 justify-content-end">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
            {/* <button type="button" className="btn btn-soft-success">
              Cancel
            </button> */}
          </div>
        </div>
        {/* <!--end col--> */}
      </div>
      {/* <!--end row--> */}
      {loading && <Loader />}
    </form>
  );
}
