import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../../hooks/axiosInstance";
import Loader from "../../utilities/Loader/Loader";

export default function WorkExprience({workExperienceDetails, fetchUserProfile}) {
  const [loading, setLoading] = useState(false)
  const [work_experience, setWorkExperience] = useState([{
    'organization': '',
    'employment_date': '',
    'leaving_date': '',
    'status': '',
    'reason': ''  
  }])

  //  listen for changes on work experience settings form inputs and store in state
  const handleExperienceChange = (index, e) => {
    const { name, value } = e.target

    setWorkExperience(prevWorkExperience => prevWorkExperience.map((t, i) => (
      i === index ? { ...t, [name]: value } : t
    )))
  }

  //    function to add work experience
  const addWorkExperience = () => {
    const experienceObject = {
      'organization': '',
      'employment_date': '',
      'leaving_date': '',
      'status': '',
      'reason': ''  
    }

    setWorkExperience(prevWorkExperience => (
      [...prevWorkExperience, experienceObject]
    ))
  }

  //    function to delete work experience
  const handleRemoveWorkExperience = (i) => {
    setWorkExperience(prevWorkExperience => (
      prevWorkExperience.filter((t, idx) => i != idx))
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    axiosInstance.post('/user/profile/work', {work_experience})
              .then(res => {
                toast.success(res.data.message)
                fetchUserProfile()
              })
              .catch(err => {
                toast.error('An error occured. Please, try again')
              })
              .finally(() => setLoading(false))
  }

  useEffect(() => {
    if(workExperienceDetails) {
      const workExperienceArray = []
      workExperienceDetails.map((experience, i) => {
        workExperienceArray.push(
          {
            organization: workExperienceDetails[i].organization, 
            employment_date: workExperienceDetails[i].employment_date, 
            leaving_date: workExperienceDetails[i].leaving_date,
            status: workExperienceDetails[i].status,
            reason: workExperienceDetails[i].reason
          }
        )
      })
      setWorkExperience(workExperienceArray)
    }
  }, [workExperienceDetails])

  return (
    <form onSubmit={handleSubmit}>
      <div id="newlink">
        <div id="1">
          <div>
            {work_experience && work_experience.map((exp, i) => (
              <div className={`${i > 0 ? 'mt-4' : ''} row`}>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="organization" className="form-label">
                      Organization
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="organization"
                      placeholder="Organization"
                      name="organization"
                      value={exp.organization}
                      onChange={(e) => handleExperienceChange(i, e)}
                    />
                  </div>
                </div>
                {/* <!--end col--> */}
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="dateemployed" className="form-label">
                      Date Employed
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateemployed"
                      name="employment_date"
                      value={exp.employment_date}
                      onChange={(e) => handleExperienceChange(i, e)}
                    />
                  </div>
                </div>
                {/* <!--end col--> */}
                {/* <!--end col--> */}
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="dateleave" className="form-label">
                      Date of Leaving
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dateleave"
                      name="leaving_date"
                      value={exp.leaving_date}
                      onChange={(e) => handleExperienceChange(i, e)}
                    />
                  </div>
                </div>
                {/* <!--end col--> */}

                <div className="col-lg-6">
                  <div className="mb-3">
                    <label htmlFor="work-status" className="form-label">
                      Status
                    </label>
                    <select
                        className="form-control"
                        id="work-status"
                        name="status"
                        value={exp.status}
                        onChange={(e) => handleExperienceChange(i, e)}
                      >
                        <option value="">--Select--</option>
                        <option value="resigned">Resigned</option>
                        <option value="sacked">Sacked</option>
                        <option value="active">Active</option>
                      </select>
                  </div>
                </div>

                <div className="col-lg-12">
                  <div className="mb-3 pb-2">
                    <label
                      htmlFor="exampleFormControlTextarea"
                      className="form-label"
                    >
                      Reason for Leaving
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea"
                      placeholder="Enter reason"
                      rows="2"
                      name="reason"
                      value={exp.reason}
                      onChange={(e) => handleExperienceChange(i, e)}
                    />
                  </div>
                </div>

                <div className="hstack gap-2 justify-content-end">
                  {i > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={(e) => handleRemoveWorkExperience(i)}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          {/* <!--end row--> */}
        </div>
      </div>
      <div id="newForm" style={{ display: "none" }}></div>
      <div className="col-lg-12">
        <div className="hstack gap-2">
          <button type="submit" className="btn btn-success">
            Update
          </button>
          <button
            type="button"
            onClick={(e) => addWorkExperience()}
            disabled={work_experience.length === 5}
            className="btn btn-primary"
          >
            {work_experience.length === 5
              ? "You Can Only add 5 work exprience"
              : "Add New"}
          </button>
        </div>
      </div>
      {/* <!--end col--> */}
      {loading && <Loader />}
    </form>
  );
}
