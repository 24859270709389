import { Link, NavLink } from "react-router-dom"

export default function AssociationAdminNewsPage() {
    return (
        <>
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">News</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/app">Dashboard</Link></li>
                                <li className="breadcrumb-item active">News</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row" id="contactList">
                <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Manage News</h4>
                            <div className="flex-shrink-0">
                                <div>
                                    <button type="button" className="btn btn-primary btn-label waves-effect waves-light w-100">
                                        <i className="ri-add-circle-line label-icon align-middle fs-16 me-2"></i> Create News
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end card header --> */}

                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div className="row g-2">
                                <div className="col-xl-3 col-md-4">
                                    <div className="search-box">
                                        <input type="text" className="form-control search" placeholder="Search forum..." />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                                <div className="col-xl-2 col-md-3">
                                    <select className="form-control" name="idStatus" id="idStatus">
                                        <option value="all">All</option>
                                        <option value="Successful">Active</option>
                                        <option value="Cancelled">Inactive</option>
                                    </select>
                                </div>
                                {/* <!--end col--> */}
                                <div className="col-xl-1 col-md-2">
                                    <button className="btn btn-success w-100" onclick="filterData();">Filters</button>
                                </div>
                            </div>
                            {/* <!--end row--> */}
                        </div>

                        <div className="card-body">
                            <div className="live-preview">
                                <div className="table-responsive">
                                    <table className="table table-striped align-middle mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Image</th>
                                                <th scope="col" width="20%">Subject</th>
                                                <th width="30%" scope="col">Body</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="fw-medium">1</td>
                                                <td>
                                                    <div class="flex-shrink-0">
                                                        <img src="/assets/images/small/img-1.jpg" className="rounded img-fluid" style={{height: "60px"}} alt="" />
                                                    </div>
                                                </td>
                                                <td>Lorem Ipsum Donor</td>
                                                <td>
                                                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante 
                                                    sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus 
                                                    viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. 
                                                    Donec lacinia congue felis in faucibus.
                                                </td>
                                                <td>Nov 21, 2021</td>
                                                <td><span className="badge bg-success">Active</span></td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="ri-more-fill"></i>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-edit-box-line text-muted me-2 align-bottom"></i>Edit</a></li>
                                                            <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-line text-muted me-2 align-bottom"></i>Delete</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-medium">2</td>
                                                <td>
                                                    <div class="flex-shrink-0">
                                                        <img src="/assets/images/small/img-2.jpg" className="rounded img-fluid" style={{height: "60px"}} alt="" />
                                                    </div>
                                                </td>
                                                <td>Lorem Ipsum Donor</td>
                                                <td>
                                                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante 
                                                    sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus 
                                                    viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. 
                                                    Donec lacinia congue felis in faucibus.
                                                </td>
                                                <td>Sep 21, 2022</td>
                                                <td><span className="badge bg-success">Active</span></td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="ri-more-fill"></i>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-edit-box-line text-muted me-2 align-bottom"></i>Edit</a></li>
                                                            <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-line text-muted me-2 align-bottom"></i>Delete</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="fw-medium">3</td>
                                                <td>
                                                    <div class="flex-shrink-0">
                                                        <img src="/assets/images/small/img-3.jpg" className="rounded img-fluid" style={{height: "60px"}} alt="" />
                                                    </div>
                                                </td>
                                                <td>Lorem Ipsum Donor</td>
                                                <td>
                                                    Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante 
                                                    sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus 
                                                    viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. 
                                                    Donec lacinia congue felis in faucibus.
                                                </td>
                                                <td>Sep 21, 2022</td>
                                                <td><span className="badge bg-danger">Inactive</span></td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <i className="ri-more-fill"></i>
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-edit-box-line text-muted me-2 align-bottom"></i>Edit</a></li>
                                                            <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-line text-muted me-2 align-bottom"></i>Delete</a></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row g-0 pt-3 text-center text-sm-start align-items-center">
                                    <div class="col-sm-6">
                                        <div>
                                            <p class="mb-sm-0 text-muted">Showing <span class="fw-semibold">1</span> to <span class="fw-semibold">10</span> of <span class="fw-semibold text-decoration-underline">12</span> entries</p>
                                        </div>
                                    </div>
                                    {/* <!-- end col --> */}
                                    <div class="col-sm-6">
                                        <ul class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                                            <li class="page-item disabled">
                                                <a href="#" class="page-link">Previous</a>
                                            </li>
                                            <li class="page-item active">
                                                <a href="#" class="page-link">1</a>
                                            </li>
                                            <li class="page-item ">
                                                <a href="#" class="page-link">2</a>
                                            </li>
                                            <li class="page-item">
                                                <a href="#" class="page-link">3</a>
                                            </li>
                                            <li class="page-item">
                                                <a href="#" class="page-link">4</a>
                                            </li>
                                            <li class="page-item">
                                                <a href="#" class="page-link">5</a>
                                            </li>
                                            <li class="page-item">
                                                <a href="#" class="page-link">Next</a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <!-- end col --> */}
                                </div>
                            </div>
                        </div>
                        {/* <!-- end card-body --> */}
                    </div>
                {/* <!--end col--> */}
            </div>
            {/* <!--end row--> */}
        </>
    )
}