import { Link, NavLink } from "react-router-dom"

export default function MembershipPage() {
    return (
        <>
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">My Membership</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/app">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Membership</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
                <div className="col-xxl-3">
                    <div className="card">
                        <div className="card-body p-4">
                            <div>
                                <div className="flex-shrink-0 avatar-md mx-auto">
                                    <div className="avatar-title bg-light rounded">
                                        <img src="/assets/images/companies/img-2.png" alt="" height="50" />
                                    </div>
                                </div>
                                <div className="mt-4 text-center">
                                    <h5 className="mb-1">Rotaract Club</h5>
                                    <p className="text-muted">RC 123464</p>
                                </div>
                                <div className="table-responsive">
                                    <table className="table mb-0 table-borderless">
                                        <tbody>
                                            <tr>
                                                <th><span className="fw-medium">Member ID</span></th>
                                                <td>RC/JS/12345</td>
                                            </tr>
                                            <tr>
                                                <th><span className="fw-medium">Fullname</span></th>
                                                <td>David Marshall</td>
                                            </tr>
                                            <tr>
                                                <th><span className="fw-medium">Level</span></th>
                                                <td>President</td>
                                            </tr>
                                            <tr>
                                                <th><span className="fw-medium">Email</span></th>
                                                <td>davidmarshals@gamil.com</td>
                                            </tr>
                                            <tr>
                                                <th><span className="fw-medium">Next Renewal date</span></th>
                                                <td>27 Sep, 2024</td>
                                            </tr>
                                            <tr>
                                                <th><span className="fw-medium">Contact No.</span></th>
                                                <td>+(123) 9876 654 321</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--end card--> */}
                </div>
                {/* <!--end col--> */}

                <div className="col-xxl-9">
                    <div className="card">
                        <div className="card-header border-0 align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Other Members</h4>
                        </div>
                        {/* <!-- end card header --> */}

                        <div className="card-body bg-light p-0 pb-2">
                            <div class="row g-4 mb-3 mt-1">
                                <div class="col-sm">
                                    <div class="d-flex gap-2">
                                        <div class="search-box ms-3">
                                            <input type="text" class="form-control" placeholder="Search..." />
                                            <i class="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                            </div>
                            <div class="row px-3">
                                <div class="col-xl-3 col-lg-4 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <div class="flex-shrink-0">
                                                    <img src="/assets/images/nft/img-01.jpg" alt="" class="avatar-sm object-fit-cover rounded" />
                                                </div>
                                                <div class="ms-3 flex-grow-1">
                                                    <a href="pages-profile.html">
                                                        <h5 class="mb-1">Timothy Smith</h5>
                                                    </a>
                                                    <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i> Vice President</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                                <div class="col-xl-3 col-lg-4 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <div class="flex-shrink-0">
                                                    <img src="/assets/images/users/avatar-5.jpg" alt="" class="avatar-sm object-fit-cover rounded" />
                                                </div>
                                                <div class="ms-3 flex-grow-1">
                                                    <a href="pages-profile.html">
                                                        <h5 class="mb-1">Alexis Clarke</h5>
                                                    </a>
                                                    <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i> Member</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                                <div class="col-xl-3 col-lg-4 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <div class="flex-shrink-0">
                                                    <img src="/assets/images/nft/img-06.jpg" alt="" class="avatar-sm object-fit-cover rounded" />
                                                </div>
                                                <div class="ms-3 flex-grow-1">
                                                    <a href="pages-profile.html">
                                                        <h5 class="mb-1">Glen Matney</h5>
                                                    </a>
                                                    <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i> Club Secretary</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                                <div class="col-xl-3 col-lg-4 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <div class="flex-shrink-0">
                                                    <img src="../../../../img.themesbrand.com/velzon/images/img-5.gif" alt="" class="avatar-sm object-fit-cover rounded" />
                                                </div>
                                                <div class="ms-3 flex-grow-1">
                                                    <a href="pages-profile.html">
                                                        <h5 class="mb-1">Herbert Stokes</h5>
                                                    </a>
                                                    <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i> Member</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                                <div class="col-xl-3 col-lg-4 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <div class="flex-shrink-0">
                                                    <img src="/assets/images/users/avatar-8.jpg" alt="" class="avatar-sm object-fit-cover rounded" />
                                                </div>
                                                <div class="ms-3 flex-grow-1">
                                                    <a href="pages-profile.html">
                                                        <h5 class="mb-1">Michael Morris</h5>
                                                    </a>
                                                    <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i> Member</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                                <div class="col-xl-3 col-lg-4 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <div class="flex-shrink-0">
                                                    <img src="/assets/images/nft/img-04.jpg" alt="" class="avatar-sm object-fit-cover rounded" />
                                                </div>
                                                <div class="ms-3 flex-grow-1">
                                                    <a href="pages-profile.html">
                                                        <h5 class="mb-1">Nancy Martino</h5>
                                                    </a>
                                                    <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i> Member</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                                <div class="col-xl-3 col-lg-4 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <div class="flex-shrink-0">
                                                    <img src="/assets/images/nft/img-05.jpg" alt="" class="avatar-sm object-fit-cover rounded" />
                                                </div>
                                                <div class="ms-3 flex-grow-1">
                                                    <a href="pages-profile.html">
                                                        <h5 class="mb-1">Mary Cousar</h5>
                                                    </a>
                                                    <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i> Member</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                                <div class="col-xl-3 col-lg-4 col-md-6">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="d-flex">
                                                <div class="flex-shrink-0">
                                                    <img src="/assets/images/nft/img-02.jpg" alt="" class="avatar-sm object-fit-cover rounded" />
                                                </div>
                                                <div class="ms-3 flex-grow-1">
                                                    <a href="pages-profile.html">
                                                        <h5 class="mb-1">James Morris</h5>
                                                    </a>
                                                    <p class="text-muted mb-0"><i class="mdi mdi-ethereum text-primary fs-14"></i> Member</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                            </div>
                            {/* <!--end row--> */}
                            {/* <!--end row--> */}
                        </div>
                        {/* <!-- end card body --> */}

                        <div class="row g-0 px-3 py-3 text-center text-sm-start align-items-center">
                            <div class="col-sm-6">
                                <div>
                                    <p class="mb-sm-0 text-muted">Showing <span class="fw-semibold">1</span> to <span class="fw-semibold">10</span> of <span class="fw-semibold text-decoration-underline">12</span> entries</p>
                                </div>
                            </div>
                            {/* <!-- end col --> */}
                            <div class="col-sm-6">
                                <ul class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                                    <li class="page-item disabled">
                                        <a href="#" class="page-link">Previous</a>
                                    </li>
                                    <li class="page-item active">
                                        <a href="#" class="page-link">1</a>
                                    </li>
                                    <li class="page-item ">
                                        <a href="#" class="page-link">2</a>
                                    </li>
                                    <li class="page-item">
                                        <a href="#" class="page-link">3</a>
                                    </li>
                                    <li class="page-item">
                                        <a href="#" class="page-link">4</a>
                                    </li>
                                    <li class="page-item">
                                        <a href="#" class="page-link">5</a>
                                    </li>
                                    <li class="page-item">
                                        <a href="#" class="page-link">Next</a>
                                    </li>
                                </ul>
                            </div>
                            {/* <!-- end col --> */}
                        </div>
                    </div>
                    {/* <!-- end card --> */}

                </div>
                {/* <!--end col--> */}
            </div>
            {/* <!--end row--> */}
        </>
    )
}