import { useEffect, useState } from "react";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../utilities/Loader/Loader";

export default function Guarantor({guarantorDetails, fetchUserProfile}) {
  const [loading, setLoading] = useState(false)
  const [guarantor, setGuarantor] = useState([{
    'title': '',
    'first_name': '',
    'last_name': '',
    'middle_name': '',
    'phone': '',
    'email': '',
    'address': '',
    'organization_name': '',
    'office_address': ''  
  }])

  //  listen for changes on guarantor settings form inputs and store in state
  const handleGuarantorChange = (index, e) => {
    const { name, value } = e.target

    setGuarantor(prevGuarantor => prevGuarantor.map((t, i) => (
      i === index ? { ...t, [name]: value } : t
    )))
  }

  //    function to add guarantor
  const addGuarantor = () => {
    const addMoreGuarantorObject = {
      'title': '',
      'first_name': '',
      'last_name': '',
      'middle_name': '',
      'phone': '',
      'email': '',
      'address': '',
      'organization_name': '',
      'office_address': ''  
    }

    setGuarantor(prevGuarantor => (
      [...prevGuarantor, addMoreGuarantorObject]
    ))
  }

  //    function to delete guarantor
  const handleRemoveGuarantor = (i) => {
    setGuarantor(prevGuarantor => (
      prevGuarantor.filter((t, idx) => i != idx))
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    axiosInstance.post('/user/profile/guarantor', {guarantor})
              .then(res => {
                toast.success(res.data.message)
                fetchUserProfile()
              })
              .catch(err => {
                toast.error('An error occured. Please, try again')
              })
              .finally(() => setLoading(false))
  }

  useEffect(() => {
    if(guarantorDetails) {
      const guarantorArray = []
      guarantorDetails.map((gua, i) => {
        guarantorArray.push(
          {
            title: guarantorDetails[i].title, 
            first_name: guarantorDetails[i].first_name, 
            last_name: guarantorDetails[i].last_name,
            middle_name: guarantorDetails[i].middle_name,
            phone: guarantorDetails[i].phone,
            email: guarantorDetails[i].email,
            address: guarantorDetails[i].address,
            office_address: guarantorDetails[i].office_address,
            organization_name: guarantorDetails[i].organization_name
          }
        )
      })
      setGuarantor(guarantorArray)
    }
  }, [guarantorDetails])

  return (
    <form onSubmit={handleSubmit}>
      <div id="newlink">
        <div id="1">
          <div className="row">
            <div className="col-lg-12">
              {guarantor && guarantor.map((gua, i) => (
                <div className={`${i > 0 ? 'mt-4' : ''} row`}>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Enter Title"
                        name="title"
                        value={gua.title}
                        onChange={(e) => handleGuarantorChange(i, e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="Surname" className="form-label">
                        Surname
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Surname"
                        placeholder="Enter Surname"
                        name="last_name"
                        value={gua.last_name}
                        onChange={(e) => handleGuarantorChange(i, e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="firstnameInput" className="form-label">
                        First Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstnameInput"
                        placeholder="Enter firstname"
                        name="first_name"
                        value={gua.first_name}
                        onChange={(e) => handleGuarantorChange(i, e)}
                      />
                    </div>
                  </div>
                  {/* <!--end col--> */}
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="middlename" className="form-label">
                        Middle Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="middlename"
                        placeholder="Enter middlename"
                        name="middle_name"
                        value={gua.middle_name}
                        onChange={(e) => handleGuarantorChange(i, e)}
                      />
                    </div>
                  </div>
                  {/* <!--end col--> */}
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="phonenumberInput2" className="form-label">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        id="phonenumberInput2"
                        placeholder="Enter Guarantor Phone number"
                        name="phone"
                        value={gua.phone}
                        onChange={(e) => handleGuarantorChange(i, e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="emailaddress" className="form-label">
                        Email Address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="emailaddress"
                        placeholder="Enter your Guarantor Email Address"
                        name="email"
                        value={gua.email}
                        onChange={(e) => handleGuarantorChange(i, e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="addressInput2" className="form-label">
                        Residential Address
                      </label>
                      <textarea
                        className="form-control"
                        id="addressInput2"
                        placeholder="Enter Guarantor Address"
                        name="address"
                        value={gua.address}
                        rows={1}
                        onChange={(e) => handleGuarantorChange(i, e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label for="organization" className="form-label">
                        Organization
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="organization"
                        placeholder="Enter Organization"
                        name="organization_name"
                        value={gua.organization_name}
                        onChange={(e) => handleGuarantorChange(i, e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label for="addressInput3" className="form-label">
                        Office Address
                      </label>
                      <textarea
                        className="form-control"
                        id="addressInput3"
                        placeholder="Enter Office Address"
                        name="office_address"
                        value={gua.office_address}
                        onChange={(e) => handleGuarantorChange(i, e)}
                      />
                    </div>
                  </div>
                  <div className="hstack gap-2 justify-content-end">
                    {i > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={(e) => handleRemoveGuarantor(i)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {/* <!--end row--> */}
            </div>

            <div className="col-lg-12">
              <div className="hstack gap-2">
                <button type="submit" className="btn btn-success">
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => addGuarantor()}
                  disabled={guarantor.length === 2}
                >
                  {guarantor.length === 2 ? "You Can Only add 2 guarantors" :"Add New"}
                    
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </form>
  );
}
