import { useEffect, useState } from "react";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../utilities/Loader/Loader";

export default function Qualification({qualificationDetails, fetchUserProfile}) {
  const [loading, setLoading] = useState(false)
  const [qualification, setQualification] = useState([{
    'qualification': '',
    'dicipline': '',
    'institution': '',
    'year': '' 
  }])

  //  listen for changes on work experience settings form inputs and store in state
  const handleQualificationChange = (index, e) => {
    const { name, value } = e.target

    setQualification(prevQualification => prevQualification.map((t, i) => (
      i === index ? { ...t, [name]: value } : t
    )))
  }

  //    function to add qualification
  const addQualification = () => {
    const qualificationObject = {
      'qualification': '',
      'dicipline': '',
      'institution': '',
      'year': '' 
    }

    setQualification(prevQualification => (
      [...prevQualification, qualificationObject]
    ))
  }

  //    function to delete qualification
  const handleRemoveQualification = (i) => {
    setQualification(prevQualification => (
      prevQualification.filter((t, idx) => i != idx))
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    axiosInstance.post('/user/profile/qualification', {qualification})
              .then(res => {
                toast.success(res.data.message)
                fetchUserProfile()
              })
              .catch(err => {
                toast.error('An error occured. Please, try again')
              })
              .finally(() => setLoading(false))
  }

  useEffect(() => {
    if(qualificationDetails) {
      const qualificationArray = []
      qualificationDetails.map((qua, i) => {
        qualificationArray.push(
          {
            qualification: qualificationDetails[i].qualification, 
            dicipline: qualificationDetails[i].dicipline, 
            institution: qualificationDetails[i].institution,
            year: qualificationDetails[i].year,
          }
        )
      })
      setQualification(qualificationArray)
    }
  }, [qualificationDetails])

  return (
    <form onSubmit={handleSubmit}>
      <div id="newlink">
        <div id="1">
          <div className="row">
            <div className="col-lg-12">
              {qualification && qualification.map((qua, i) => (
                <div className={`${i > 0 ? 'mt-4' : ''} row`}>
                  <div className="col-lg-6" key={i}>
                    <div className="mb-3">
                      <label htmlFor="qualification" className="form-label">
                        Qualification
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="qualification"
                        placeholder="Enter your Qualification"
                        name="qualification"
                        value={qua.qualification}
                        onChange={(e) => handleQualificationChange(i, e)}
                      />
                    </div>
                  </div>
                  {/* <!--end col--> */}
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="discipline" className="form-label">
                        Discipline
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="discipline"
                        placeholder="Enter your Discipline"
                        name="dicipline"
                        value={qua.dicipline}
                        onChange={(e) => handleQualificationChange(i, e)}
                      />
                    </div>
                  </div>
                  {/* <!--end col--> */}
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="institution" className="form-label">
                        Institution
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="institution"
                        placeholder="Enter your Institution"
                        name="institution"
                        value={qua.institution}
                        onChange={(e) => handleQualificationChange(i, e)}
                      />
                    </div>
                  </div>
                  {/* <!--end col--> */}
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor="year" className="form-label">
                        Year
                      </label>
                      <input
                        type="number"
                        min="1900"
                        max="2100"
                        className="form-control"
                        id="year"
                        placeholder="Enter a year"
                        name="year"
                        value={qua.year}
                        onChange={(e) => handleQualificationChange(i, e)}
                      />
                    </div>
                  </div>
                  <div className="hstack gap-2 justify-content-end">
                    {i > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={(e) => handleRemoveQualification(i)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {/* <!--end row--> */}
            </div>

            <div className="col-lg-12">
              <div className="hstack gap-2">
                <button type="submit" className="btn btn-success">
                  Update
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => addQualification()}
                  disabled={qualification.length === 5}
                >
                  {qualification.length === 5
                    ? "You Can Only add 5 Qualifications"
                    : "Add New"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </form>
  );
}
