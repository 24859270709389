export default function  Footer() {
    return (
        <footer class="footer">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-6">
                        2023 © InnerMember.
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-end d-none d-sm-block">
                            Design & Develop by DIGITAL WEB
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}