import { useEffect, useState } from "react";
import Loader from "../../utilities/Loader/Loader";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuthContext";

export default function PersonalDetail({personalDetail, fetchUserProfile}) {
  const {userData} = useAuth()

  const [formData, setFormData] = useState({
    'title': '',
    'first_name': '',
    'last_name': '',
    'middle_name': '',
    'address': '',
    'dob': '',
    'phone': '',
    'alternate_phone': ''
  })
  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const {name, value} = e.target

    setFormData(prevFormData => (
      {...prevFormData, [name]: value}
    ))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    formData.email = userData.userInfo.user.email
    axiosInstance.post('/user/profile', formData)
              .then(res => {
                toast.success(res.data.message)
                fetchUserProfile()
              })
              .catch(err => {
                toast.error('An error occured. Please, try again')
              })
              .finally(() => setLoading(false))
  }

  useEffect(() => {
    setFormData({
      'title': personalDetail?.title,
      'first_name': personalDetail?.first_name,
      'last_name': personalDetail?.last_name,
      'middle_name': personalDetail?.middle_name,
      'address': personalDetail?.address,
      'dob': personalDetail?.dob,
      'phone': personalDetail?.phone,
      'alternate_phone': personalDetail?.alternate_phone
    })
  }, [personalDetail])

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-12">
          <div className="mb-3">
            <label htmlFor="title" className="form-label">
              Title
            </label>
            <input
              type="text"
              className="form-control"
              id="title"
              placeholder="Enter Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="Surname" className="form-label">
              Surname
            </label>
            <input
              type="text"
              className="form-control"
              id="Surname"
              placeholder="Enter your Surname"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="firstnameInput" className="form-label">
              First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="firstnameInput"
              placeholder="Enter your firstname"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="middlename" className="form-label">
              Middle Name
            </label>
            <input
              type="text"
              className="form-control"
              id="middlename"
              placeholder="Enter your middlename"
              name="middle_name"
              value={formData.middle_name}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}
        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="dob" className="form-label">
              Date of Birth
            </label>
            <input
              type="date"
              className="form-control"
              id="dob"
              name="dob"
              value={formData.dob}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="phonenumberInput" className="form-label">
              Phone Number 1
            </label>
            <input
              type="text"
              className="form-control"
              id="phonenumberInput"
              placeholder="Enter your phone number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}
        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="phonenumberInput2" className="form-label">
              Phone Number 2
            </label>
            <input
              type="text"
              className="form-control"
              id="phonenumberInput2"
              placeholder="Enter your phone number"
              name="alternate_phone"
              value={formData.alternate_phone}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="col-lg-12">
          <div className="mb-3 pb-2">
            <label htmlFor="exampleFormControlTextarea" className="form-label">
              Residential Address
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea"
              placeholder="Enter your address"
              rows="3"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
        </div>
        {/* <!--end col--> */}

        <div className="col-lg-12">
          <div className="hstack gap-2 justify-content-end">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
            {/* <button type="button" className="btn btn-soft-success">
              Cancel
            </button> */}
          </div>
        </div>
        {/* <!--end col--> */}
      </div>
      {loading && <Loader />}
      {/* <!--end row--> */}
    </form>
  );
}
