export default function AccountChangePassword() {
  return (
    <form action="javascript:void(0);">
      <div className="row">
        <div class="col-lg-4">
          <div>
            <label htmlFor="oldpasswordInput" className="form-label">
              Old Password*
            </label>
            <input
              type="password"
              className="form-control"
              id="oldpasswordInput"
              placeholder="Enter current password"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div>
            <label htmlFor="newpasswordInput" className="form-label">
              New Password*
            </label>
            <input
              type="password"
              className="form-control"
              id="newpasswordInput"
              placeholder="Enter new password"
            />
          </div>
        </div>
        <div class="col-lg-4">
          <div>
            <label htmlFor="confirmpasswordInput" className="form-label">
              Confirm Password*
            </label>
            <input
              type="password"
              className="form-control"
              id="confirmpasswordInput"
              placeholder="Confirm password"
            />
          </div>
        </div>
        <div className="col-lg-12 mt-3">
          <div className="hstack gap-2 justify-content-end">
            <button type="submit" className="btn btn-primary">
              Change Password
            </button>
            {/* <button type="button" className="btn btn-soft-success">
              Cancel
            </button> */}
          </div>
        </div>
      </div>
    </form>
  );
}
