import { useEffect } from "react"
import './Home.css'
import HeaderPage from "../../components/weblayouts/Header"
import FooterPage from "../../components/weblayouts/Footer"
import { NavLink } from "react-router-dom"

/**
 * Easy on scroll event listener 
 */
const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
}

/**
   * Easy selector helper function
   */
const select = (el, all = false) => {
    el = el.trim()
    if (all) {
        return [...document.querySelectorAll(el)]
    } else {
        return document.querySelector(el)
    }
}

/**
 * Easy event listener function
 */
const on = (type, el, listener, all = false) => {
    let selectEl = select(el, all)
    if (selectEl) {
        if (all) {
            selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
            selectEl.addEventListener(type, listener)
        }
    }
}

/**
 * Scrolls to an element with header offset
 */
const scrollto = (el) => {
    let header = select('#header')
    let offset = header.offsetHeight

    let elementPos = select(el).offsetTop
    window.scrollTo({
        top: elementPos - offset,
        behavior: 'smooth'
    })
}

/**
   * Mobile nav toggle
   */
document.addEventListener('click', function (e) {
  // Check if the clicked element has the class "mobile-nav-toggle"
  if (e.target.matches('.mobile-nav-toggle')) {
    // Toggle the classes on the relevant elements
    select('#navbar').classList.toggle('navbar-mobile');
    e.target.classList.toggle('bi-list');
    e.target.classList.toggle('bi-x');
  }
});

export default function HomePage() {
    useEffect(() => {
        /**
         * Navbar links active state on scroll
         */
        let navbarlinks = select('#navbar .scrollto', true)
        const navbarlinksActive = () => {
            let position = window.scrollY + 200
            navbarlinks.forEach(navbarlink => {
                if (!navbarlink.hash) return
                let section = select(navbarlink.hash)
                if (!section) return
                if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                    navbarlink.classList.add('active')
                } else {
                    navbarlink.classList.remove('active')
                }
            })
        }
        window.addEventListener('load', navbarlinksActive)
        onscroll(document, navbarlinksActive)

        /**
         * Toggle .header-scrolled class to #header when page is scrolled
         */
        let selectHeader = document.querySelector('#header')
        if (selectHeader) {
            const headerScrolled = () => {
                if (window.scrollY > 100) {
                    selectHeader.classList.add('header-scrolled')
                } else {
                    selectHeader.classList.remove('header-scrolled')
                }
            }
            window.addEventListener('load', headerScrolled)
            onscroll(document, headerScrolled)
        }

        /**
         * Back to top button
         */
        let backtotop = document.querySelector('.back-to-top')
        if (backtotop) {
            const toggleBacktotop = () => {
                if (window.scrollY > 100) {
                    backtotop.classList.add('active')
                } else {
                    backtotop.classList.remove('active')
                }
            }
            window.addEventListener('load', toggleBacktotop)
            onscroll(document, toggleBacktotop)
        }


        /**
         * Scrool with ofset on links with a class name .scrollto
         */
        on('click', '.scrollto', function (e) {
            if (select(this.hash)) {
                e.preventDefault()

                let navbar = select('#navbar')
                if (navbar.classList.contains('navbar-mobile')) {
                    navbar.classList.remove('navbar-mobile')
                    let navbarToggle = select('.mobile-nav-toggle')
                    navbarToggle.classList.toggle('bi-list')
                    navbarToggle.classList.toggle('bi-x')
                }
                scrollto(this.hash)
            }
        }, true)


    }, [])
    return (
        <>
            <HeaderPage />
                {/* ======= Hero Section ======= */}
                <section id="hero" className="d-flex align-items-center">

                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay="200">
                                <h1>Collaborative Platform for Membership Management</h1>
                                <h4 style={{ color: '#bbbbbb' }}>We help you seamlessly manage various membership activities</h4>
                                <div className="d-flex justify-content-center justify-content-lg-start">
                                    <NavLink to="/auth/register" className="btn-get-started scrollto">Get Started</NavLink>
                                    {/* <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a> */}
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
                                <img src="/assets/img/hero-img.png" className="img-fluid animated" alt="" />
                            </div>
                        </div>
                    </div>

                </section>
                {/* End Hero */}

                {/* ======= Main Section ======= */}
                <main id="main">
                    <section id="clients" className="clients section-bg">
                        <div className="container">

                            <div className="row" data-aos="zoom-in">
                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img src="/assets/img/clients/client-7.png" className="img-fluid" alt="" />
                                </div>

                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img src="/assets/img/clients/client-8.png" className="img-fluid" alt="" />
                                </div>

                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img src="/assets/img/clients/client-9.png" className="img-fluid" alt="" />
                                </div>

                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img src="/assets/img/clients/client-10.png" className="img-fluid" alt="" />
                                </div>

                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img src="/assets/img/clients/client-11.png" className="img-fluid" alt="" />
                                </div>

                                <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                                    <img src="/assets/img/clients/client-12.png" className="img-fluid" alt="" />
                                </div>

                            </div>

                        </div>
                    </section>

                    <section id="about" className="about">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h2>About Us</h2>
                            </div>

                            <div className="row content">
                                <div className="col-lg-12 pt-4 pt-lg-0 text-center">
                                    <p>
                                        InnerMember is a versatile collaborative platform designed to streamline and 
                                        enhance membership-related functions for organizations. 
                                        This comprehensive solution empowers organizations to effortlessly manage 
                                        diverse membership activities, including application processes, member lists, 
                                        geographic data, dues management, and more. 
                                        Furthermore, InnerMember offers the convenience of access via both web 
                                        and mobile platforms, ensuring seamless connectivity and accessibility for 
                                        users on the go.
                                    </p>
                                    <a href="#" className="btn-learn-more">Learn More</a>
                                </div>
                                <div className="col-12 mt-5 pt-md-4 pt-3">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 mb-3">
                                            <div className="about-grid">
                                                <div className="borderRounded"></div>
                                                <div className="descriptionText">
                                                    <p>Manage Member List</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 mb-3">
                                            <div className="about-grid">
                                                <div className="borderRounded"></div>
                                                <div className="descriptionText">
                                                    <p>Collect Dues Seamlessly</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 mb-3">
                                            <div className="about-grid">
                                                <div className="borderRounded"></div>
                                                <div className="descriptionText">
                                                    <p>Robust Messaging Capabilities</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>

                    {/* <section id="why-us" className="why-us section-bg">
                        <div className="container-fluid" data-aos="fade-up">

                            <div className="row">

                            <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch  order-2 order-lg-1">

                                <div className="content">
                                <h3>Eum ipsam laborum deleniti <strong>velit pariatur architecto aut nihil</strong></h3>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit
                                </p>
                                </div>

                                <div className="accordion-list">
                                <ul>
                                    <li>
                                    <a data-bs-toggle="collapse" className="collapse" data-bs-target="#accordion-list-1"><span>01</span> Non consectetur a erat nam at lectus urna duis? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-1" className="collapse show" data-bs-parent=".accordion-list">
                                        <p>
                                        Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                                        </p>
                                    </div>
                                    </li>

                                    <li>
                                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-2" className="collapsed"><span>02</span> Feugiat scelerisque varius morbi enim nunc? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-2" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                        </p>
                                    </div>
                                    </li>

                                    <li>
                                    <a data-bs-toggle="collapse" data-bs-target="#accordion-list-3" className="collapsed"><span>03</span> Dolor sit amet consectetur adipiscing elit? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                    <div id="accordion-list-3" className="collapse" data-bs-parent=".accordion-list">
                                        <p>
                                        Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                                        </p>
                                    </div>
                                    </li>

                                </ul>
                                </div>

                            </div>

                            <div className="col-lg-5 align-items-stretch order-1 order-lg-2 img" 
                                style={{backgroundImage: 'url("/assets/img/why-us.png")'}} data-aos="zoom-in" data-aos-delay="150">&nbsp;</div>
                            </div>

                        </div>
                    </section>

                    <section id="skills" className="skills">
                        <div className="container" data-aos="fade-up">

                            <div className="row">
                            <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
                                <img src="/assets/img/skills.png" className="img-fluid" alt="" />
                            </div>
                            <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
                                <h3>Voluptatem dignissimos provident quasi corporis voluptates</h3>
                                <p className="fst-italic">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                                magna aliqua.
                                </p>

                                <div className="skills-content">

                                <div className="progress">
                                    <span className="skill">HTML <i className="val">100%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">CSS <i className="val">90%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">JavaScript <i className="val">75%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">Photoshop <i className="val">55%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
                                    </div>
                                </div>

                                </div>

                            </div>
                            </div>

                        </div>
                    </section> */}

                    <section id="services" className="services section-bg">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h2>Features</h2>
                                <p>
                                    We specialize in providing a comprehensive range of services to enhance your membership experience.
                                    From managing membership dues to fostering engaging discussions in our forum, keeping you updated with the latest news,
                                    facilitating seamless messaging, and tailoring your association profile to your needs, we've got you covered.
                                </p>
                            </div>

                            <div className="row">
                                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-lg-4 mb-1" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <div className="icon"><i className="mdi mdi-account-multiple-outline"></i></div>
                                        <h4><a href="">Membership</a></h4>
                                        <p>Join our thriving community and gain access to a wealth of resources and networking opportunities.</p>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-lg-4 mb-1" data-aos="zoom-in" data-aos-delay="100">
                                    <div className="icon-box">
                                        <div className="icon"><i className="mdi mdi-cash"></i></div>
                                        <h4><a href="">Dues</a></h4>
                                        <p>Effortlessly managing membership dues with our streamlined and efficient system</p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-lg-4 mb-1 mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
                                    <div className="icon-box">
                                        <div className="icon"><i className="mdi mdi-forum-outline"></i></div>
                                        <h4><a href="">Forum</a></h4>
                                        <p>Engage in meaningful discussions and connect with a diverse community of experts through our interactive forum.</p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-lg-4 mb-1 mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
                                    <div className="icon-box">
                                        <div className="icon"><i className="mdi mdi-newspaper-variant-outline"></i></div>
                                        <h4><a href="">News</a></h4>
                                        <p>Stay informed and stay ahead with the latest updates, trends, and developments </p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-lg-4 mb-1 mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                                    <div className="icon-box">
                                        <div className="icon"><i className="mdi mdi-message-text-fast-outline"></i></div>
                                        <h4><a href="">Messaging</a></h4>
                                        <p>Streamline communication and foster seamless collaboration with our messaging platform. </p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-lg-4 mb-1 mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                                    <div className="icon-box">
                                        <div className="icon"><i className="mdi mdi-cog-sync-outline"></i></div>
                                        <h4><a href="">Settings</a></h4>
                                        <p>Tailor your preferences and personalize your interactions to suit your needs.</p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-lg-4 mb-1 mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                                    <div className="icon-box">
                                        <div className="icon"><i className="mdi mdi-account-circle-outline"></i></div>
                                        <h4><a href="">Association Profile</a></h4>
                                        <p>Showcase your organization's unique identity and strengthen your online presence. </p>
                                    </div>
                                </div>

                                <div className="col-xl-3 col-md-6 d-flex align-items-stretch mb-lg-4 mb-1 mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
                                    <div className="icon-box">
                                        <div className="icon"><i className="mdi mdi-map-marker-multiple-outline"></i></div>
                                        <h4><a href="">Location</a></h4>
                                        <p>Unlock the potential of your surroundings and maximize the value of your membership experience.</p>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>

                    <section id="cta" className="cta">
                        <div className="container" data-aos="zoom-in">

                            <div className="row">
                                <div className="col-lg-9 text-center text-lg-start">
                                    <h3>Come on board</h3>
                                    <p> 
                                        Begin the journey to revolutionise your membership management process with our platform. 
                                        You can start with a free trial to experience membership management on a different level
                                    </p>
                                </div>
                                <div className="col-lg-3 cta-btn-container text-center">
                                    <a className="cta-btn align-middle" href="#">Start</a>
                                </div>
                            </div>

                        </div>
                    </section>

                    {/* <section id="portfolio" className="portfolio">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                            <h2>Portfolio</h2>
                            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                            </div>

                            <ul id="portfolio-flters" className="d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
                            <li data-filter="*" className="filter-active">All</li>
                            <li data-filter=".filter-app">App</li>
                            <li data-filter=".filter-card">Card</li>
                            <li data-filter=".filter-web">Web</li>
                            </ul>

                            <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

                            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                <div className="portfolio-img"><img src="/assets/img/portfolio/portfolio-1.jpg" className="img-fluid" alt="" /></div>
                                <div className="portfolio-info">
                                <h4>App 1</h4>
                                <p>App</p>
                                <a href="/assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 1"><i className="bx bx-plus"></i></a>
                                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                <div className="portfolio-img"><img src="/assets/img/portfolio/portfolio-2.jpg" className="img-fluid" alt="" /></div>
                                <div className="portfolio-info">
                                <h4>Web 3</h4>
                                <p>Web</p>
                                <a href="/assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"><i className="bx bx-plus"></i></a>
                                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                <div className="portfolio-img"><img src="/assets/img/portfolio/portfolio-3.jpg" className="img-fluid" alt="" /></div>
                                <div className="portfolio-info">
                                <h4>App 2</h4>
                                <p>App</p>
                                <a href="/assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 2"><i className="bx bx-plus"></i></a>
                                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                <div className="portfolio-img"><img src="/assets/img/portfolio/portfolio-4.jpg" className="img-fluid" alt="" /></div>
                                <div className="portfolio-info">
                                <h4>Card 2</h4>
                                <p>Card</p>
                                <a href="/assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 2"><i className="bx bx-plus"></i></a>
                                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                <div className="portfolio-img"><img src="/assets/img/portfolio/portfolio-5.jpg" className="img-fluid" alt="" /></div>
                                <div className="portfolio-info">
                                <h4>Web 2</h4>
                                <p>Web</p>
                                <a href="/assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 2"><i className="bx bx-plus"></i></a>
                                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                                <div className="portfolio-img"><img src="/assets/img/portfolio/portfolio-6.jpg" className="img-fluid" alt="" /></div>
                                <div className="portfolio-info">
                                <h4>App 3</h4>
                                <p>App</p>
                                <a href="/assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="App 3"><i className="bx bx-plus"></i></a>
                                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                <div className="portfolio-img"><img src="/assets/img/portfolio/portfolio-7.jpg" className="img-fluid" alt="" /></div>
                                <div className="portfolio-info">
                                <h4>Card 1</h4>
                                <p>Card</p>
                                <a href="/assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 1"><i className="bx bx-plus"></i></a>
                                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                                <div className="portfolio-img"><img src="/assets/img/portfolio/portfolio-8.jpg" className="img-fluid" alt="" /></div>
                                <div className="portfolio-info">
                                <h4>Card 3</h4>
                                <p>Card</p>
                                <a href="/assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Card 3"><i className="bx bx-plus"></i></a>
                                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                                <div className="portfolio-img"><img src="/assets/img/portfolio/portfolio-9.jpg" className="img-fluid" alt="" /></div>
                                <div className="portfolio-info">
                                <h4>Web 3</h4>
                                <p>Web</p>
                                <a href="/assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" className="portfolio-lightbox preview-link" title="Web 3"><i className="bx bx-plus"></i></a>
                                <a href="portfolio-details.html" className="details-link" title="More Details"><i className="bx bx-link"></i></a>
                                </div>
                            </div>

                            </div>

                        </div>
                    </section> */}

                    {/* <section id="team" className="team section-bg"> 
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                            <h2>Team</h2>
                            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                            </div>

                            <div className="row">

                            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="100">
                                <div className="member d-flex align-items-start">
                                <div className="pic"><img src="/assets/img/team/team-1.jpg" className="img-fluid" alt="" /></div>
                                <div className="member-info">
                                    <h4>Walter White</h4>
                                    <span>Chief Executive Officer</span>
                                    <p>Explicabo voluptatem mollitia et repellat qui dolorum quasi</p>
                                    <div className="social">
                                    <a href=""><i className="ri-twitter-fill"></i></a>
                                    <a href=""><i className="ri-facebook-fill"></i></a>
                                    <a href=""><i className="ri-instagram-fill"></i></a>
                                    <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="200">
                                <div className="member d-flex align-items-start">
                                <div className="pic"><img src="/assets/img/team/team-2.jpg" className="img-fluid" alt="" /></div>
                                <div className="member-info">
                                    <h4>Sarah Jhonson</h4>
                                    <span>Product Manager</span>
                                    <p>Aut maiores voluptates amet et quis praesentium qui senda para</p>
                                    <div className="social">
                                    <a href=""><i className="ri-twitter-fill"></i></a>
                                    <a href=""><i className="ri-facebook-fill"></i></a>
                                    <a href=""><i className="ri-instagram-fill"></i></a>
                                    <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay="300">
                                <div className="member d-flex align-items-start">
                                <div className="pic"><img src="/assets/img/team/team-3.jpg" className="img-fluid" alt="" /></div>
                                <div className="member-info">
                                    <h4>William Anderson</h4>
                                    <span>CTO</span>
                                    <p>Quisquam facilis cum velit laborum corrupti fuga rerum quia</p>
                                    <div className="social">
                                    <a href=""><i className="ri-twitter-fill"></i></a>
                                    <a href=""><i className="ri-facebook-fill"></i></a>
                                    <a href=""><i className="ri-instagram-fill"></i></a>
                                    <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay="400">
                                <div className="member d-flex align-items-start">
                                <div className="pic"><img src="/assets/img/team/team-4.jpg" className="img-fluid" alt="" /></div>
                                <div className="member-info">
                                    <h4>Amanda Jepson</h4>
                                    <span>Accountant</span>
                                    <p>Dolorum tempora officiis odit laborum officiis et et accusamus</p>
                                    <div className="social">
                                    <a href=""><i className="ri-twitter-fill"></i></a>
                                    <a href=""><i className="ri-facebook-fill"></i></a>
                                    <a href=""><i className="ri-instagram-fill"></i></a>
                                    <a href=""> <i className="ri-linkedin-box-fill"></i> </a>
                                    </div>
                                </div>
                                </div>
                            </div>

                            </div>

                        </div>
                    </section> */}

                    <section id="pricing" className="pricing section-bg">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h2>Pricing</h2>
                                <p>Our pricing plans are designed to be flexible, allowing you to choose the features and options that matter most to you. With our transparent pricing structure, you can easily see what you're paying for and make an informed decision.</p>
                            </div>

                            <div className="row">

                                <div className="col-lg-3 mb-3" data-aos="fade-up" data-aos-delay="100">
                                    <div className="box">
                                        <h3>Starter Plan</h3>
                                        <h4><sup> ₦</sup>0<span>per annum</span></h4>
                                        <small className="text-success"><b>1 - 10 Users</b></small>
                                        <ul>
                                            <li><i className="bx bx-check"></i> Membership</li>
                                            <li><i className="bx bx-check"></i> Dues</li>
                                            <li><i className="bx bx-check"></i> Forum</li>
                                            <li><i className="bx bx-check"></i> News</li>
                                            <li><i className="bx bx-check"></i> Messaging</li>
                                            <li><i className="bx bx-check"></i> Settings</li>

                                            {/* <li className="na"><i className="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
                                        <li className="na"><i className="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li> */}
                                        </ul>
                                        <NavLink to="/auth/register" className="buy-btn">Get Started</NavLink>
                                    </div>
                                </div>

                                <div className="col-lg-3 mb-3" data-aos="fade-up" data-aos-delay="100">
                                    <div className="box">
                                        <h3>Basic Plan</h3>
                                        <h4><sup> ₦</sup>1,500<span>per annum</span></h4>
                                        <small className="text-success"><b>100 - 1,000 Users</b></small>
                                        <ul>
                                            <li><i className="bx bx-check"></i> Membership</li>
                                            <li><i className="bx bx-check"></i> Dues</li>
                                            <li><i className="bx bx-check"></i> Forum</li>
                                            <li><i className="bx bx-check"></i> News</li>
                                            <li><i className="bx bx-check"></i> Messaging</li>
                                            <li><i className="bx bx-check"></i> Settings</li>

                                            {/* <li className="na"><i className="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
                                        <li className="na"><i className="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li> */}
                                        </ul>
                                        <NavLink to="/auth/register" className="buy-btn">Get Started</NavLink>
                                    </div>
                                </div>

                                <div className="col-lg-3 mb-3" data-aos="fade-up" data-aos-delay="100">
                                    <div className="box">
                                        <h3>Standard Plan</h3>
                                        <h4><sup> ₦</sup>1,250<span>per annum</span></h4>
                                        <small className="text-success"><b>1,000 - 10,000 Users</b></small>
                                        <ul>
                                            <li><i className="bx bx-check"></i> Membership</li>
                                            <li><i className="bx bx-check"></i> Dues</li>
                                            <li><i className="bx bx-check"></i> Forum</li>
                                            <li><i className="bx bx-check"></i> News</li>
                                            <li><i className="bx bx-check"></i> Messaging</li>
                                            <li><i className="bx bx-check"></i> Settings</li>

                                            {/* <li className="na"><i className="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
                                        <li className="na"><i className="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li> */}
                                        </ul>
                                        <NavLink to="/auth/register" className="buy-btn">Get Started</NavLink>
                                    </div>
                                </div>

                                <div className="col-lg-3 mb-3" data-aos="fade-up" data-aos-delay="100">
                                    <div className="box">
                                        <h3>Premium Plan</h3>
                                        <h4><sup> ₦</sup>1,000<span>per annum</span></h4>
                                        <small className="text-success"><b>Above 10,000 Users</b></small>
                                        <ul>
                                            <li><i className="bx bx-check"></i> Membership</li>
                                            <li><i className="bx bx-check"></i> Dues</li>
                                            <li><i className="bx bx-check"></i> Forum</li>
                                            <li><i className="bx bx-check"></i> News</li>
                                            <li><i className="bx bx-check"></i> Messaging</li>
                                            <li><i className="bx bx-check"></i> Settings</li>

                                            {/* <li className="na"><i className="bx bx-x"></i> <span>Pharetra massa massa ultricies</span></li>
                                        <li className="na"><i className="bx bx-x"></i> <span>Massa ultricies mi quis hendrerit</span></li> */}
                                        </ul>
                                        <NavLink to="/auth/register" className="buy-btn">Get Started</NavLink>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </section>

                    {/* <section id="faq" className="faq section-bg">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                            <h2>Frequently Asked Questions</h2>
                            <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                            </div>

                            <div className="faq-list">
                            <ul>
                                <li data-aos="fade-up" data-aos-delay="100">
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">Non consectetur a erat nam at lectus urna duis? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
                                    <p>
                                    Feugiat pretium nibh ipsum consequat. Tempus iaculis urna id volutpat lacus laoreet non curabitur gravida. Venenatis lectus magna fringilla urna porttitor rhoncus dolor purus non.
                                    </p>
                                </div>
                                </li>

                                <li data-aos="fade-up" data-aos-delay="200">
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">Feugiat scelerisque varius morbi enim nunc? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    Dolor sit amet consectetur adipiscing elit pellentesque habitant morbi. Id interdum velit laoreet id donec ultrices. Fringilla phasellus faucibus scelerisque eleifend donec pretium. Est pellentesque elit ullamcorper dignissim. Mauris ultrices eros in cursus turpis massa tincidunt dui.
                                    </p>
                                </div>
                                </li>

                                <li data-aos="fade-up" data-aos-delay="300">
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">Dolor sit amet consectetur adipiscing elit? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    Eleifend mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Faucibus pulvinar elementum integer enim. Sem nulla pharetra diam sit amet nisl suscipit. Rutrum tellus pellentesque eu tincidunt. Lectus urna duis convallis convallis tellus. Urna molestie at elementum eu facilisis sed odio morbi quis
                                    </p>
                                </div>
                                </li>

                                <li data-aos="fade-up" data-aos-delay="400">
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">Tempus quam pellentesque nec nam aliquam sem et tortor consequat? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    Molestie a iaculis at erat pellentesque adipiscing commodo. Dignissim suspendisse in est ante in. Nunc vel risus commodo viverra maecenas accumsan. Sit amet nisl suscipit adipiscing bibendum est. Purus gravida quis blandit turpis cursus in.
                                    </p>
                                </div>
                                </li>

                                <li data-aos="fade-up" data-aos-delay="500">
                                <i className="bx bx-help-circle icon-help"></i> <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">Tortor vitae purus faucibus ornare. Varius vel pharetra vel turpis nunc eget lorem dolor? <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i></a>
                                <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                                    <p>
                                    Laoreet sit amet cursus sit amet dictum sit amet justo. Mauris vitae ultricies leo integer malesuada nunc vel. Tincidunt eget nullam non nisi est sit amet. Turpis nunc eget lorem dolor sed. Ut venenatis tellus in metus vulputate eu scelerisque.
                                    </p>
                                </div>
                                </li>

                            </ul>
                            </div>

                        </div>
                    </section> */}

                    <section id="contact" className="contact">
                        <div className="container" data-aos="fade-up">

                            <div className="section-title">
                                <h2>Contact</h2>
                                <p>
                                    Feel free to reach out to us through the provided contact information or by filling out the contact
                                    form below. We aim to respond promptly and ensure that your experience with us
                                    is smooth and satisfactory.
                                </p>
                            </div>

                            <div className="row">

                                <div className="col-lg-5 d-flex align-items-stretch">
                                    <div className="info">
                                        <div className="address">
                                            <i className="bi bi-geo-alt"></i>
                                            <h4>Location:</h4>
                                            <p>8B Ogidi Crescent, Lekki Phase 1, Lagos</p>
                                        </div>

                                        <div className="email">
                                            <i className="bi bi-envelope"></i>
                                            <h4>Email:</h4>
                                            <p>info@innermember.com</p>
                                        </div>

                                        <div className="phone">
                                            <i className="bi bi-phone"></i>
                                            <h4>Call:</h4>
                                            <p>+234 703 410 7070</p>
                                        </div>
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5377261974104!2d3.469884475793124!3d6.4533362239730625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103bf4448afa985f%3A0x883effdbd2971aa9!2s8b%20Ogidi%20Cres%2C%20Lekki%20Phase%20I%20105102%2C%20Lagos!5e0!3m2!1sen!2sng!4v1689587033301!5m2!1sen!2sng"
                                            style={{ border: 0, width: '100%', height: '290px' }} allowFullScreen></iframe>
                                    </div>

                                </div>

                                <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                                    <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                                        <div className="row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="name">Your Name</label>
                                                <input type="text" name="name" className="form-control" id="name" required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="name">Your Email</label>
                                                <input type="email" className="form-control" name="email" id="email" required />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name">Subject</label>
                                            <input type="text" className="form-control" name="subject" id="subject" required />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="name">Message</label>
                                            <textarea className="form-control" name="message" rows="10" required></textarea>
                                        </div>
                                        <div className="my-3">
                                            <div className="loading">Loading</div>
                                            <div className="error-message"></div>
                                            <div className="sent-message">Your message has been sent. Thank you!</div>
                                        </div>
                                        <div className="text-center"><button type="submit">Send Message</button></div>
                                    </form>
                                </div>

                            </div>

                        </div>
                    </section>

                </main>
                {/* End Main */}
            <FooterPage />
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        </>
    )
}