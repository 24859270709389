import PersonalDetail from "../../components/Member/Account/Personal";
import ChangePassword from "../../components/Member/Account/ChangePassword";

export default function MemberAccountPage() {
    return (
        <>
            <div className="position-relative mx-n4 mt-n4">
                <div className="profile-wid-bg profile-setting-img">
                    <img src="/assets/images/profile-bg.jpg" className="profile-wid-img" alt="" />
                    {/* <div className="overlay-content">
                        <div className="text-end p-3">
                            <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                                <input id="profile-foreground-img-file-input" type="file" className="profile-foreground-img-file-input" />
                                <label for="profile-foreground-img-file-input" className="profile-photo-edit btn btn-light">
                                    <i className="ri-image-edit-line align-bottom me-1"></i> Change Cover
                                </label>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="row">
                <div className="col-xxl-3">
                    <div className="card mt-n5">
                        <div className="card-body p-4">
                            <div className="text-center">
                                <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                    <img src="/assets/images/users/avatar-1.jpg" className="rounded-circle avatar-xl img-thumbnail user-profile-image" alt="user-profile-image" />
                                    <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                        <input id="profile-img-file-input" type="file" className="profile-img-file-input" />
                                        <label for="profile-img-file-input" className="profile-photo-edit avatar-xs">
                                            <span className="avatar-title rounded-circle bg-light text-body">
                                                <i className="ri-camera-fill"></i>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                                <h5 className="fs-16 mb-1">Anna Adame</h5>
                                <p className="text-muted mb-0">Club Secretary</p>
                            </div>
                        </div>
                    </div>
                    {/* <!--end card--> */}
                    {/* <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-5">
                                <div className="flex-grow-1">
                                    <h5 className="card-title mb-0">Complete Your Profile</h5>
                                </div>
                                <div className="flex-shrink-0">
                                    <a href="javascript:void(0);" className="badge bg-light text-primary fs-12"><i className="ri-edit-box-line align-bottom me-1"></i> Edit</a>
                                </div>
                            </div>
                            <div className="progress animated-progress custom-progress progress-label">
                                <div className="progress-bar bg-danger" role="progressbar" style={{ width: "30%" }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">
                                    <div className="label">30%</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="card">
                        <div className="card-body">
                            <div className="d-flex align-items-center mb-4">
                                <div className="flex-grow-1">
                                    <h5 className="card-title mb-0">Socials</h5>
                                </div>
                                <div className="flex-shrink-0">
                                    <a href="javascript:void(0);" className="badge bg-light text-primary fs-12"><i className="ri-add-fill align-bottom me-1"></i> Add</a>
                                </div>
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="avatar-xs d-block flex-shrink-0 me-3">
                                    <span className="avatar-title rounded-circle fs-16 bg-body text-body">
                                        <i className="ri-github-fill"></i>
                                    </span>
                                </div>
                                <input type="email" className="form-control" id="gitUsername" placeholder="Username" value="@daveadame" />
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="avatar-xs d-block flex-shrink-0 me-3">
                                    <span className="avatar-title rounded-circle fs-16 bg-primary">
                                        <i className="ri-global-fill"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" id="websiteInput" placeholder="www.example.com" value="www.velzon.com" />
                            </div>
                            <div className="mb-3 d-flex">
                                <div className="avatar-xs d-block flex-shrink-0 me-3">
                                    <span className="avatar-title rounded-circle fs-16 bg-success">
                                        <i className="ri-dribbble-fill"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" id="dribbleName" placeholder="Username" value="@dave_adame" />
                            </div>
                            <div className="d-flex">
                                <div className="avatar-xs d-block flex-shrink-0 me-3">
                                    <span className="avatar-title rounded-circle fs-16 bg-danger">
                                        <i className="ri-pinterest-fill"></i>
                                    </span>
                                </div>
                                <input type="text" className="form-control" id="pinterestName" placeholder="Username" value="Advance Dave" />
                            </div>
                        </div>
                    </div> */}
                    {/* <!--end card--> */}
                </div>
                {/* <!--end col--> */}
                <div className="col-xxl-9">
                    <div className="card mt-xxl-n5">
                        <div className="card-header">
                            <ul className="nav nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#personalDetails" role="tab">
                                        <i className="fas fa-home"></i> Personal Details 
                                    </a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#changePassword" role="tab">
                                        <i className="far fa-user"></i> Change Password
                                    </a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#changepassword" role="tab">
                                        <i className="far fa-envelope"></i> Change Password 
                                    </a>
                                </li>
                             
                            </ul>
                        </div>
                        <div className="card-body p-4">
                            <div className="tab-content">
                                <div className="tab-pane active" id="personalDetails" role="tabpanel">
                                    <PersonalDetail />
                                </div>
                                <div className="tab-pane" id="changepassword" role="tabpanel">
                                  <ChangePassword />
                                </div>
                              
                                {/* <!--end tab-pane--> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!--end col--> */}
            </div>
            {/* <!--end row--> */}
        </>
    )
}