import { Link, NavLink } from "react-router-dom"

export default function ForumPage() {
    return (
        <>
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Forum</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/app">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Forum</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- end page title --> */}

            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header align-items-center d-flex">
                            <h4 class="card-title mb-0 flex-grow-1"></h4>
                        </div>
                        {/* <!-- end card header --> */}

                        <div class="card-body">

                            {/* <p class="text-muted mb-4">The images or other media can be aligned top, middle, or bottom. The default is top aligned.</p> */}

                            <div class="live-preview">
                                <div class="d-flex align-items-start text-muted mb-4">
                                    <div class="flex-shrink-0 me-3">
                                        <img src="/assets/images/users/avatar-2.jpg" class="avatar-sm rounded" alt="..." />
                                    </div>

                                    <div class="flex-grow-1">
                                        <h5 class="fs-15">Top Aligned media <small class="text-muted fs-13 fw-normal">- 15 Dec, 2021</small></h5>
                                        <p class="mb-1">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                                        <p class="mb-0">Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                                    </div>
                                </div>

                                <div class="d-flex align-items-start text-muted mb-4">
                                    <div class="flex-shrink-0 me-3">
                                        <img src="/assets/images/users/avatar-6.jpg" class="avatar-sm rounded" alt="..." />
                                    </div>

                                    <div class="flex-grow-1">
                                        <h5 class="fs-15">Center Aligned media <small class="text-muted fs-13 fw-normal">- 10 Dec, 2022</small></h5>
                                        <p class="mb-1">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                                        <p class="mb-0">Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                                    </div>
                                </div>

                                <div class="d-flex align-items-start text-muted">
                                    <div class="flex-shrink-0 me-3">
                                        <img src="/assets/images/users/avatar-8.jpg" class="avatar-sm rounded" alt="..." />
                                    </div>

                                    <div class="flex-grow-1">
                                        <h5 class="fs-15">Bottom Aligned media <small class="text-muted fs-13 fw-normal">- 15 Dec, 2021</small></h5>
                                        <p class="mb-1">Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                                        <p class="mb-0">Donec sed odio dui. Nullam quis risus eget urna mollis ornare vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end card-body --> */}
                    </div>
                    {/* <!-- end card --> */}
                </div>
                {/* <!-- end col --> */}
            </div>
            {/* <!-- end row --> */}
        </>
    )
}