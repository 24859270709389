export default function AccountPersonalDetail() {
  return (
    <form action="javascript:void(0);">
      <div className="row">
      <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="firstname" className="form-label">
             First Name
            </label>
            <input
              type="text"
              className="form-control"
              id="firstname"
              placeholder="Enter your Firstname"
              value="Dave"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="lastname" className="form-label">
             Last Name
            </label>
            <input
              type="text"
              className="form-control"
              id="lastname"
              placeholder="Enter your firstname"
              value="Chukwudi"
            />
          </div>
        </div>
        {/* <!--end col--> */}
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="middlename" className="form-label">
              Middle Name
            </label>
            <input
              type="text"
              className="form-control"
              id="middlename"
              placeholder="Enter your middlename"
              value="Adame"
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="mb-3">
            <label htmlFor="phonenumberInput" className="form-label">
              Phone Number 
            </label>
            <input
              type="text"
              className="form-control"
              id="phonenumberInput"
              placeholder="Enter your phone number"
              value="+(1) 987 6543"
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="mb-3">
            <label for="emailadress" className="form-label">
              Email Address
            </label>
            <input
              type="email"
              className="form-control"
              id="emailadress"
              placeholder="Enter Company Email"
              value="Adame@gmail.com"
            />
          </div>
        </div>
      
      
      
        <div className="col-lg-12">
          <div className="hstack gap-2 justify-content-end">
            <button type="submit" className="btn btn-primary">
              Update
            </button>
            <button type="button" className="btn btn-soft-success">
              Cancel
            </button>
          </div>
        </div>
        {/* <!--end col--> */}
      </div>
      {/* <!--end row--> */}
    </form>
  );
}
