import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import axiosInstance from "../../hooks/axiosInstance";
import ReactPasswordChecklist from 'react-password-checklist';

export default function AssociationRegister() {
    const navigate = useNavigate()

    const [formData, setFormData] = useState({
        email:'',
        password:'',
        password_confirmation:'',
        name: '',
    })
    const [registerLoading, setRegisterLoading] = useState(false)
    const [validationError, setValidationError] = useState({})

    const [toggle, setToggle] = useState(false)
    const [toggleConfirm, setToggleConfirm] = useState(false)

    const handleToggle = () =>{
        setToggle(!toggle)
    }

    const handleConfirmToggle = () =>{
        setToggleConfirm(!toggleConfirm)
    }

    const resetFormData = () => {
        setFormData({
            email:'',
            password:'',
            password_confirmation:'',
            name: '',
        })
    }

    const handleChange = (e) => {
        const {name, value} = e.target

        setFormData(prevFormData => (
            {...prevFormData, [name]: value}
        ))
    }

    const handleSubmit = (e, type) => {
        e.preventDefault()
        if(formData.password !== formData.password_confirmation){
            toast.error("Password do not match")
            return
        }
        setRegisterLoading(true)
        setValidationError({})
        axiosInstance.post('/association/auth/register', formData)
            .then(res => {
                toast.success("Account Created Succesfully")
                if(res.data.data){
                    localStorage.setItem('verificationData', JSON.stringify(res.data.data))
                }
                resetFormData()
                navigate("/auth/association/verify-email")
            })
            .catch(err => {
                toast.error("An error occured. Please, try again")
                if(err.response.status == 422) {
                    setValidationError(err.response.data.errors)
                }
                console.log(err.response.data);
            })
            .finally(() => setRegisterLoading(false))
    }

    return (
        <div className="p-2 mt-4">
            <form className="needs-validation" novalidate onSubmit={(e) => handleSubmit(e, 'member')}>
                <div className="mb-3">
                    <label for="className=" className="form-label">Association Email <span className="text-danger">*</span></label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email address"
                        name='email'
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    {validationError.email && (<small className="text-danger mt-1 mb-0">{validationError.email[0]}</small>)}
                </div>

                <div className="mb-3">
                        <label for="className=" className="form-label">Association Name <span className="text-danger">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter association name"
                            name='name'
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                        {validationError.name && (<small className="text-danger mt-1 mb-0">{validationError.name[0]}</small>)}
                    </div>

                <div className="mb-3">
                    <label className="form-label" for="password-input">Password</label>
                    <div className="position-relative auth-pass-inputgroup">
                        <input
                            type={toggle ? `text` : `password`}
                            className="form-control pe-5 password-input"
                            placeholder="Enter password"
                            aria-describedby="passwordInput"
                            onChange={handleChange}
                            value={formData.password}
                            name='password'
                            required
                        />
                        <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button" onClick={handleToggle}
                        >
                            <i className={`${toggle ? 'ri-eye-off-fill' : 'ri-eye-fill'} align-middle`}></i>
                        </button>
                        <div className="invalid-feedback">
                            Please enter password
                        </div>
                    </div>
                    {formData.password && (
                        <ReactPasswordChecklist
                            className="p-3 bg-light mb-2 mt-2 rounded"
                            rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                "capital",
                                "match",
                            ]}
                            minLength={5}
                            value={formData.password}
                            valueAgain={formData.password_confirmation}
                        />
                    )}
                </div>

                <div className="mb-3">
                    <label className="form-label" for="password-input">Confirm Password</label>
                    <div className="position-relative auth-pass-inputgroup">
                        <input
                            type={toggleConfirm ? `text` : `password`}
                            className="form-control pe-5 password-input"
                            placeholder="Enter password"
                            aria-describedby="passwordInput"
                            onChange={handleChange}
                            value={formData.password_confirmation}
                            name='password_confirmation'
                        />
                        <button
                            className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                            type="button" onClick={handleConfirmToggle}
                        >
                            <i className={`${toggleConfirm ? 'ri-eye-off-fill' : 'ri-eye-fill'} align-middle`}></i>
                        </button>
                    </div>
                </div>

                <div className="mb-4">
                    <p className="mb-0 fs-12 text-muted fst-italic">By registering you agree to the Inner Member <a href="#" className="text-primary text-decoration-underline fst-normal fw-medium">Terms of Use</a></p>
                </div>

                <div className="mt-4">
                    <button className="btn btn-success w-100" type="submit" disabled={registerLoading}>
                        {registerLoading ? <div class="spinner-border spinner-border-sm text-light"></div> : 'Sign Up'}
                    </button>
                </div>
            </form>
        </div>
    )
}