import { Link } from  "react-router-dom"

export default function OpenAssociationsPage() {
    return (
        <>
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">Open Associations</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/app">Dashboard</Link></li>
                                <li className="breadcrumb-item active">Open Associations</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- end page title --> */}


            <div className="card">
                <div className="card-body">
                    <div className="row g-3">
                        {/* <div className="col-xxl-4 col-lg-6">
                            <div className="search-box">
                                <input type="text" className="form-control" placeholder="Search to invitations..." />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </div>
                        {/* <!--end col--> */}
                        {/* <div className="col-xxl-3 col-lg-6">
                            <input type="text" className="form-control" data-date-format="d M, Y" data-range-date="true" placeholder="Select date" />
                        </div> */}
                        <div className="col-xxl-4 col-lg-4">
                            <select className="form-control" name="choices-single-default2" id="choices-single-default2">
                                <option value="">All</option>
                                <option value="free">Free</option>
                                <option value="Paid">Paid</option>
                            </select>
                        </div>
                        {/* <!--end col--> */}
                        <div className="col-xxl-1 col-lg-4">
                            <button className="btn btn-primary w-100"><i className="ri-equalizer-line align-bottom me-1"></i> Filters</button>
                        </div>
                    </div>
                    {/* <!--end row--> */}
                </div>
            </div>

            <div className="row">
                <div className="col-xxl-3 col-sm-6 project-card">
                    <div className="card card-height-100">
                        <div className="card-body">
                            <div className="d-flex flex-column h-100">
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <span class="badge badge-gradient-secondary mb-4">Free</span>
                                    </div>
                                </div>
                                <div className="d-flex mb-0">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm">
                                            <span className="avatar-title bg-warning-subtle rounded p-2">
                                                <img src="/assets/images/brands/slack.png" alt="" className="img-fluid p-1" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-15"><a href="apps-projects-overview.html" className="text-body">Rotaract Club of Akoka</a></h5>
                                        <p className="text-muted text-truncate-two-lines mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid </p>
                                    </div>
                                </div>
                                <div className="mt-auto">
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1">
                                            <div>Members</div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div>20</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- end card body --> */}
                        <div className="card-footer bg-transparent border-top-dashed py-2">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <div className="avatar-group">
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Darline Williams">
                                            <div className="avatar-xxs">
                                                <img src="/assets/images/users/avatar-2.jpg" alt="" className="rounded-circle img-fluid" />
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Sylvia Wright">
                                            <div className="avatar-xxs">
                                                <div className="avatar-title rounded-circle bg-secondary">
                                                    S
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <a href="#" type="button" className="btn btn-soft-primary btn-sm flex-shrink-0 ms-2">
                                        Join
                                    </a>
                                </div>

                            </div>

                        </div>
                        {/* <!-- end card footer --> */}
                    </div>
                    {/* <!-- end card --> */}
                </div>
                {/* <!-- end col --> */}

                <div className="col-xxl-3 col-sm-6 project-card">
                    <div className="card card-height-100">
                        <div className="card-body">
                            <div className="d-flex flex-column h-100">
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <span class="badge badge-gradient-warning mb-4">Paid</span>
                                    </div>
                                </div>
                                <div className="d-flex mb-0">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm">
                                            <span className="avatar-title bg-danger-subtle rounded p-2">
                                                <img src="/assets/images/brands/dribbble.png" alt="" className="img-fluid p-1" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-15"><a href="apps-projects-overview.html" className="text-body">NASFAT</a></h5>
                                        <p className="text-muted text-truncate-two-lines mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid </p>
                                    </div>
                                </div>
                                <div className="mt-auto">
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1">
                                            <div>Members</div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div>20</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- end card body --> */}
                        <div className="card-footer bg-transparent border-top-dashed py-2">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <div className="avatar-group">
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Brent Gonzalez">
                                            <div className="avatar-xxs">
                                                <img src="/assets/images/users/avatar-3.jpg" alt="" className="rounded-circle img-fluid" />
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Sylvia Wright">
                                            <div className="avatar-xxs">
                                                <div className="avatar-title rounded-circle bg-secondary">
                                                    S
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Ellen Smith">
                                            <div className="avatar-xxs">
                                                <img src="/assets/images/users/avatar-4.jpg" alt="" className="rounded-circle img-fluid" />
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <a href="#" type="button" className="btn btn-soft-primary btn-sm flex-shrink-0 ms-2">
                                        Join
                                    </a>
                                </div>

                            </div>

                        </div>
                        {/* <!-- end card footer --> */}
                    </div>
                    {/* <!-- end card --> */}
                </div>
                {/* <!-- end col --> */}

                <div className="col-xxl-3 col-sm-6 project-card">
                    <div className="card card-height-100">
                        <div className="card-body">
                            <div className="d-flex flex-column h-100">
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <span class="badge badge-gradient-warning mb-4">Paid</span>
                                    </div>
                                </div>
                                <div className="d-flex mb-0">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm">
                                            <span className="avatar-title bg-success-subtle rounded p-2">
                                                <img src="/assets/images/brands/mail_chimp.png" alt="" className="img-fluid p-1" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-15"><a href="apps-projects-overview.html" className="text-body">AGGS Old girls association</a></h5>
                                        <p className="text-muted text-truncate-two-lines mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid </p>
                                    </div>
                                </div>
                                <div className="mt-auto">
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1">
                                            <div>Members</div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div>20</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- end card body --> */}
                        <div className="card-footer bg-transparent border-top-dashed py-2">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <div className="avatar-group">
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Brent Gonzalez">
                                            <div className="avatar-xxs">
                                                <img src="/assets/images/users/avatar-3.jpg" alt="" className="rounded-circle img-fluid" />
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Sylvia Wright">
                                            <div className="avatar-xxs">
                                                <div className="avatar-title rounded-circle bg-secondary">
                                                    S
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Ellen Smith">
                                            <div className="avatar-xxs">
                                                <img src="/assets/images/users/avatar-4.jpg" alt="" className="rounded-circle img-fluid" />
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Jeffrey Salazar">
                                            <div className="avatar-xxs">
                                                <img src="/assets/images/users/avatar-5.jpg" alt="" className="rounded-circle img-fluid" />
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Mark Williams">
                                            <div className="avatar-xxs">
                                                <div className="avatar-title rounded-circle bg-warning">
                                                    M
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <a href="#" type="button" className="btn btn-soft-primary btn-sm flex-shrink-0 ms-2">
                                        Join
                                    </a>
                                </div>

                            </div>

                        </div>
                        {/* <!-- end card footer --> */}
                    </div>
                    {/* <!-- end card --> */}
                </div>
                {/* <!-- end col --> */}

                <div className="col-xxl-3 col-sm-6 project-card">
                    <div className="card card-height-100">
                        <div className="card-body">
                            <div className="d-flex flex-column h-100">
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <span class="badge badge-gradient-secondary mb-4">Free</span>
                                    </div>
                                </div>
                                <div className="d-flex mb-0">
                                    <div className="flex-shrink-0 me-3">
                                        <div className="avatar-sm">
                                            <span className="avatar-title bg-info-subtle rounded p-2">
                                                <img src="/assets/images/brands/dropbox.png" alt="" className="img-fluid p-1" />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex-grow-1">
                                        <h5 className="mb-1 fs-15"><a href="apps-projects-overview.html" className="text-body">Nigeria Computer Society</a></h5>
                                        <p className="text-muted text-truncate-two-lines mb-3">Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid </p>
                                    </div>
                                </div>
                                <div className="mt-auto">
                                    <div className="d-flex mb-2">
                                        <div className="flex-grow-1">
                                            <div>Members</div>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <div>2,020</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- end card body --> */}
                        <div className="card-footer bg-transparent border-top-dashed py-2">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <div className="avatar-group">
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Brent Gonzalez">
                                            <div className="avatar-xxs">
                                                <img src="/assets/images/users/avatar-3.jpg" alt="" className="rounded-circle img-fluid" />
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Sylvia Wright">
                                            <div className="avatar-xxs">
                                                <div className="avatar-title rounded-circle bg-secondary">
                                                    S
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Ellen Smith">
                                            <div className="avatar-xxs">
                                                <img src="/assets/images/users/avatar-4.jpg" alt="" className="rounded-circle img-fluid" />
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Jeffrey Salazar">
                                            <div className="avatar-xxs">
                                                <img src="/assets/images/users/avatar-5.jpg" alt="" className="rounded-circle img-fluid" />
                                            </div>
                                        </a>
                                        <a href="javascript: void(0);" className="avatar-group-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Mark Williams">
                                            <div className="avatar-xxs">
                                                <div className="avatar-title rounded-circle bg-warning">
                                                    M
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <a href="#" type="button" className="btn btn-soft-primary btn-sm flex-shrink-0 ms-2">
                                        Join
                                    </a>
                                </div>

                            </div>

                        </div>
                        {/* <!-- end card footer --> */}
                    </div>
                    {/* <!-- end card --> */}
                </div>
                {/* <!-- end col --> */}
            </div>
            {/* <!-- end row --> */}

            <div className="row g-0 text-center text-sm-start align-items-center mb-4">
                <div className="col-sm-6">
                    <div>
                        <p className="mb-sm-0 text-muted">Showing <span className="fw-semibold">1</span> to <span className="fw-semibold">10</span> of <span className="fw-semibold text-decoration-underline">12</span> entries</p>
                    </div>
                </div>
                {/* <!-- end col --> */}
                <div className="col-sm-6">
                    <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                        <li className="page-item disabled">
                            <a href="#" className="page-link">Previous</a>
                        </li>
                        <li className="page-item active">
                            <a href="#" className="page-link">1</a>
                        </li>
                        <li className="page-item ">
                            <a href="#" className="page-link">2</a>
                        </li>
                        <li className="page-item">
                            <a href="#" className="page-link">3</a>
                        </li>
                        <li className="page-item">
                            <a href="#" className="page-link">4</a>
                        </li>
                        <li className="page-item">
                            <a href="#" className="page-link">5</a>
                        </li>
                        <li className="page-item">
                            <a href="#" className="page-link">Next</a>
                        </li>
                    </ul>
                </div>
                {/* <!-- end col --> */}
            </div>
            {/* <!-- end row --> */}
        </>
    )
}