import { Link, NavLink } from "react-router-dom"

export default function MyAssociationsPage() {
    return (
        <>
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">My Associations</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/app">Dashboard</Link></li>
                                <li className="breadcrumb-item active">My Associations</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row" id="contactList">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header d-flex align-items-center border-0">
                            <h5 className="card-title mb-0 flex-grow-1">My Associations</h5>
                            {/* <div className="flex-shrink-0">
                                <div className="flax-shrink-0 hstack gap-2">
                                    <button className="btn btn-primary">Today's Orders</button>
                                    <button className="btn btn-soft-info">Past Orders</button>
                                </div>
                            </div> */}
                        </div>
                        <div className="card-body border border-dashed border-end-0 border-start-0">
                            <div className="row g-2">
                                <div className="col-xl-4 col-md-6">
                                    <div className="search-box">
                                        <input type="text" className="form-control search" placeholder="Search to associations..." />
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                                {/* <!--end col--> */}
                                {/* <div className="col-xl-3 col-md-6">
                                    <div className="input-group">
                                        <span className="input-group-text" id="basic-addon1"><i className="ri-calendar-2-line"></i></span>
                                        <input type="text" className="form-control" data-date-format="d M, Y" data-range-date="true" placeholder="Select date" id="range-datepicker" aria-describedby="basic-addon1" />
                                    </div>
                                </div> */}
                                {/* <!--end col--> */}
                                <div className="col-xl-2 col-md-4">
                                    <select className="form-control" name="idType" id="idType">
                                        <option value="all">Select Type</option>
                                        <option value="Buy">Free</option>
                                        <option value="Sell">Paid</option>
                                    </select>
                                </div>
                                {/* <!--end col--> */}
                                <div className="col-xl-2 col-md-4">
                                    <select className="form-control" name="idStatus" id="idStatus">
                                        <option value="all">Select Status</option>
                                        <option value="Successful">Active</option>
                                        <option value="Cancelled">Inactive</option>
                                        {/* <option value="Pending">Pending</option> */}
                                    </select>
                                </div>
                                <div className="col-xl-2 col-md-4">
                                    <select className="form-control" name="idStatus" id="idStatus">
                                        <option value="all">Select Membership Status</option>
                                        <option value="Successful">Active</option>
                                        <option value="Cancelled">Expired</option>
                                        {/* <option value="Pending">Pending</option> */}
                                    </select>
                                </div>
                                {/* <!--end col--> */}
                                <div className="col-xl-1 col-md-4">
                                    <button className="btn btn-success w-100" onclick="filterData();">Filters</button>
                                </div>
                            </div>
                            {/* <!--end row--> */}
                        </div>
                        <div className="card-body">
                            <div className="table-responsive table-card">
                                <table className="table align-middle table-nowrap" id="customerTable">
                                    <thead className="table-light text-muted">
                                        <tr>
                                            <th className="sort" data-sort="currency_name" scope="col">Association Info</th>
                                            <th className="sort" data-sort="type" scope="col">Membership Type</th>
                                            <th className="sort" data-sort="quantity_value" scope="col">Membership ID</th>
                                            <th className="sort" data-sort="time" scope="col">Date Joined</th>
                                            <th className="sort" data-sort="status" scope="col">Status</th>
                                            <th className="sort" data-sort="status" scope="col">Membership Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {/* <!--end thead--> */}
                                    <tbody className="list form-check-all">
                                        <tr>
                                            <td className="id" style={{display:"none"}}><a href="javascript:void(0);" className="fw-medium link-primary">#VZ001</a></td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <img src="/assets/images/svg/crypto-icons/btc.svg" alt="" className="avatar-xs" />
                                                    </div>
                                                    <NavLink to={'/app/associations/1'} className="flex-grow-1 ms-2 currency_name">
                                                        <span className="m-0">Rotary Club</span>
                                                        <p className="m-0 text-muted" style={{lineHeight: 1.5}}>rotary_international@hotmail.com</p>
                                                    </NavLink>
                                                </div>
                                            </td>
                                            <td className="type text-success">Free</td>
                                            <td className="id fw-medium link-primary">#VZ001</td>
                                            <td className="order_date time" data-timestamp="1641945600">
                                                02 Jan, 2022
                                            </td>
                                            <td className="status">
                                                <span className="badge bg-success-subtle text-success text-uppercase">Active</span>
                                             </td>
                                             <td className="status">
                                                <span className="badge bg-success-subtle text-success text-uppercase">Active</span>
                                             </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="ri-more-fill"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Renew Membership</a></li>
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Leave</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* <!--end tr--> */}
                                        <tr>
                                            <td className="id" style={{display:"none"}}><a href="javascript:void(0);" className="fw-medium link-primary">#VZ001</a></td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <img src="/assets/images/svg/crypto-icons/eth.svg" alt="" className="avatar-xs" />
                                                    </div>
                                                    <NavLink to={'/app/associations/2'} className="flex-grow-1 ms-2 currency_name">
                                                        <span className="m-0">Leo Club</span>
                                                        <p className="m-0 text-muted" style={{lineHeight: 1.5}}>leo@gmail.com</p>
                                                    </NavLink>
                                                </div>
                                            </td>
                                            <td className="type text-danger">Paid</td>
                                            <td className="id fw-medium link-primary">#LEO_1234</td>
                                            <td className="order_date time" data-timestamp="1641945600">
                                                10 Jun, 2021
                                            </td>
                                            <td className="status">
                                                <span className="badge bg-success-subtle text-success text-uppercase">Active</span>
                                             </td>
                                             <td className="status">
                                                <span className="badge bg-danger-subtle text-danger text-uppercase">Expired</span>
                                             </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="ri-more-fill"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Renew Membership</a></li>
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Leave</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="id" style={{display:"none"}}><a href="javascript:void(0);" className="fw-medium link-primary">#VZ001</a></td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <img src="/assets/images/svg/crypto-icons/mkr.svg" alt="" className="avatar-xs" />
                                                    </div>
                                                    <NavLink to={'/app/associations/3'} className="flex-grow-1 ms-2 currency_name">
                                                        <span className="m-0">Nigeria Computer Society</span>
                                                        <p className="m-0 text-muted" style={{lineHeight: 1.5}}>ncs@gmail.com</p>
                                                    </NavLink>
                                                </div>
                                            </td>
                                            <td className="type text-danger">Paid</td>
                                            <td className="id fw-medium link-primary">NCS/POG/324</td>
                                            <td className="order_date time" data-timestamp="1641945600">
                                                22 Sep, 2019
                                            </td>
                                            <td className="status">
                                                <span className="badge bg-success-subtle text-success text-uppercase">Active</span>
                                             </td>
                                             <td className="status">
                                                <span className="badge bg-success-subtle text-success text-uppercase">Active</span>
                                             </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className="btn btn-icon btn-sm fs-16 text-muted dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="ri-more-fill"></i>
                                                    </button>
                                                    <ul className="dropdown-menu">
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-eye-fill text-muted me-2 align-bottom"></i>View</a></li>
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-star-fill text-muted me-2 align-bottom"></i>Renew Membership</a></li>
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-delete-bin-5-fill text-muted me-2 align-bottom"></i>Leave</a></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                        {/* <!--end tr--> */}
                                    </tbody>
                                </table>
                                {/* <!--end table--> */}
                                <div className="noresult" style={{display: "none"}}>
                                    <div className="text-center">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop" colors="primary:#405189,secondary:#0ab39c" style={{width:"75px",height:"75px"}}></lord-icon>
                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                        <p className="text-muted mb-0">We've searched more than 150+ orders We did not find any orders for you search.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mt-3">
                                <div className="pagination-wrap hstack gap-2">
                                    <a className="page-item pagination-prev disabled" href="#">
                                        Previous
                                    </a>
                                    <ul className="pagination listjs-pagination mb-0"></ul>
                                    <a className="page-item pagination-next" href="#">
                                        Next
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!--end card--> */}
                </div>
                {/* <!--end col--> */}
            </div>
            {/* <!--end row--> */}
        </>
    )
}