import { Link, NavLink } from "react-router-dom"

export default function AssociationNewsPage() {
    return (
        <>
            {/* <!-- start page title --> */}
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">News</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><Link to="/app">Dashboard</Link></li>
                                <li className="breadcrumb-item active">News</li>
                            </ol>
                        </div>

                    </div>
                </div>
            </div>
            {/* <!-- end page title --> */}

            <div className="row">
                <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">News</h4>
                        </div>
                        {/* <!-- end card header --> */}

                        <div className="card-body">
                            <div className="live-preview">
                                <div className="list-group">
                                    <a href="javascript:void(0);" className="list-group-item list-group-item-action">
                                        <div className="float-end">
                                            <ul className="list-inline card-toolbar-menu d-flex align-items-center mb-0">
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-eye-outline align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-message-outline align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-share-variant align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-heart-outline align-middle"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src="/assets/images/users/avatar-1.jpg" alt="" className="avatar-sm rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="list-title fs-15 mb-1">Charlie Pritchard</h5>
                                                <p className="list-text mb-0 fs-12">12 min Ago</p>
                                            </div>
                                        </div>
                                        <p className="list-text mb-0">They all have something to say beyond the words on the page. They can come across as casual or neutral, exotic or graphic. That's why it's important to think about your message, then choose a font that fits. Cosby sweater eu banh mi, qui irure terry richardson ex squid.</p>
                                    </a>
                                    <a href="javascript:void(0);" className="list-group-item list-group-item-action">
                                        <div className="float-end">
                                            <ul className="list-inline card-toolbar-menu d-flex align-items-center mb-0">
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-eye-outline align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-message-outline align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-share-variant align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-heart-outline align-middle"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src="/assets/images/users/avatar-2.jpg" alt="" className="avatar-sm rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="list-title fs-15 mb-1">Dominic Charlton</h5>
                                                <p className="list-text mb-0 fs-12">12 min Ago</p>
                                            </div>
                                        </div>
                                        <p className="list-text mb-0">Trust fund seitan letterpress, keytar raw denim keffiyeh etsy art party before they sold out master cleanse gluten-free squid scenester freegan cosby sweater. Fanny pack portland seitan DIY, art party locavore wolf cliche high life echo park Austin.</p>
                                    </a>
                                    <a href="javascript:void(0);" className="list-group-item list-group-item-action">
                                        <div className="float-end">
                                            <ul className="list-inline card-toolbar-menu d-flex align-items-center mb-0">
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-eye-outline align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-message-outline align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-share-variant align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-heart-outline align-middle"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src="/assets/images/users/avatar-3.jpg" alt="" className="avatar-sm rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="list-title fs-15 mb-1">Declan Long</h5>
                                                <p className="list-text mb-0 fs-12">12 min Ago</p>
                                            </div>
                                        </div>
                                        <p className="list-text mb-0">Big July earthquakes confound zany experimental vow. My girl wove six dozen plaid jackets before she quit. Six big devils from Japan quickly forgot how to waltz. try again until it looks right, and each assumenda labore aes Homo nostrud organic, assumenda labore aesthetic magna elements, buttons, everything.</p>
                                    </a>
                                    <a href="javascript:void(0);" className="list-group-item list-group-item-action">
                                        <div className="float-end">
                                            <ul className="list-inline card-toolbar-menu d-flex align-items-center mb-0">
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-eye-outline align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-message-outline align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-share-variant align-middle"></i>
                                                    </span>
                                                </li>
                                                <li className="list-inline-item">
                                                    <span className="align-middle" href="javascript:void(0);">
                                                        <i className="mdi mdi-heart-outline align-middle"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="d-flex mb-2 align-items-center">
                                            <div className="flex-shrink-0">
                                                <img src="/assets/images/users/avatar-4.jpg" alt="" className="avatar-sm rounded-circle" />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h5 className="list-title fs-15 mb-1">Angela Bernier</h5>
                                                <p className="list-text mb-0 fs-12">5 days Ago</p>
                                            </div>
                                        </div>
                                        <p className="list-text mb-0">Just like in the image where we talked about using multiple fonts, you can see that the background in this graphic design is blurred. Whenever you put text on top of an image, it’s important that your viewers can understand the text, and sometimes that means applying a gaussian readable.</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end card-body --> */}
                    </div>
                    {/* <!-- end card --> */}
                </div>
                {/* <!-- end col --> */}
            </div>
            {/* <!--end row--> */}
        </>
    )
}