// Routes.js
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import BaseLayout from './components/Layouts/BaseLayout';
import WebBaseLayout from './components/weblayouts/WebBaseLayout';
import HomePage from './Pages/WebPage/Home';
import DashboardPage from './Pages/Member/Dashboard';
import AssociationDashboardPage from './Pages/Member/AssociationDetails/Dashboard';
import LoginPage from './Pages/Member/Auth/Login';
import RegisterPage from './Pages/Member/Auth/Register';
import ForgotPasswordPage from './Pages/Member/Auth/ForgotPassword';
import PasswordChangePage from './Pages/Member/Auth/ChangePassword';
import MemberProfilePage from './Pages/Member/Profile';
import MemberAccountPage from './Pages/Member/Account';
import MyApplicationsPage from './Pages/Member/MyApplications';
import MyInvitationsPage from './Pages/Member/MyInvitations';
import OpenAssociationsPage from './Pages/Member/OpenAssociations';
import MyAssociationsPage from './Pages/Member/MyAssociations';
import MembershipPage from './Pages/Member/AssociationDetails/Membership';
import ForumPage from './Pages/Member/AssociationDetails/Forum';
import AssociationEventsPage from './Pages/Member/AssociationDetails/Events';
import AssociationMeetingsPage from './Pages/Member/AssociationDetails/Meetings';
import AssociationNewsPage from './Pages/Member/AssociationDetails/News';
import AssociationAdminDashboardPage from './Pages/AssociationAdmin/Dashboard';
import AssociationAdminProfilePage from './Pages/AssociationAdmin/Profile';
import MembersListPage from './Pages/AssociationAdmin/Members';
import AssociationAdminApplicationsPage from './Pages/AssociationAdmin/Applications';
import MeetingsListPage from './Pages/AssociationAdmin/Meetings';
import AssociationAdminForumPage from './Pages/AssociationAdmin/Forum';
import AssociationAdminNewsPage from './Pages/AssociationAdmin/News';
import VerifyEmailPage from './Pages/Member/Auth/VerifyEmail';
import EmailVerifiedPage from './Pages/Member/Auth/EmailVerified';
import VerifyAssociationEmailPage from './Pages/AssociationAdmin/Auth/VerifyEmail';
import AssociationEmailVerifiedPage from './Pages/AssociationAdmin/Auth/EmailVerified';
import AssociationLoginPage from './Pages/AssociationAdmin/Auth/Login';
import AssociationForgotPasswordPage from './Pages/AssociationAdmin/Auth/ForgotPassword';
import AssociationPasswordChangePage from './Pages/AssociationAdmin/Auth/ChangePassword';

const AppRoutes = () => {
  return (
    <Routes>
        <Route path="/">
            <Route index element={<Navigate to="/home" />} />
            <Route path="auth">
                <Route path="login" element={<LoginPage />} />
                <Route path="register" element={<RegisterPage />} />
                <Route path="forgot-password" element={<ForgotPasswordPage />} />
                <Route path="reset-password" element={<PasswordChangePage />} />
                <Route path="verify-email" element={<VerifyEmailPage />} />
                <Route path="email-verified" element={<EmailVerifiedPage />} />

                <Route path='association'>
                    <Route path="login" element={<AssociationLoginPage />} />
                    <Route path='verify-email' element={<VerifyAssociationEmailPage />} />
                    <Route path='email-verified' element={<AssociationEmailVerifiedPage />} />
                    <Route path="forgot-password" element={<AssociationForgotPasswordPage />} />
                    <Route path="reset-password" element={<AssociationPasswordChangePage />} />
                </Route>
            </Route>
            <Route path="app">
                <Route path="dashboard" element={<BaseLayout><DashboardPage /></BaseLayout>} />
                <Route path="profile" element={<BaseLayout><MemberProfilePage /></BaseLayout>} />
                <Route path="applications" element={<BaseLayout><MyApplicationsPage /></BaseLayout>} />
                <Route path="invitations" element={<BaseLayout><MyInvitationsPage /></BaseLayout>} />
                <Route path="open_associations" element={<BaseLayout><OpenAssociationsPage /></BaseLayout>} />
                <Route path="account" element={<BaseLayout><MemberAccountPage /></BaseLayout>} />
                <Route path="associations">
                    <Route path="" element={<BaseLayout><MyAssociationsPage /></BaseLayout>} />
                    <Route path=":associationId" element={<BaseLayout><AssociationDashboardPage /></BaseLayout>} />
                    <Route path=":associationId/membership" element={<BaseLayout><MembershipPage /></BaseLayout>} />
                    <Route path=":associationId/forum" element={<BaseLayout><ForumPage /></BaseLayout>} />
                    <Route path=":associationId/events" element={<BaseLayout><AssociationEventsPage /></BaseLayout>} />
                    <Route path=":associationId/meetings" element={<BaseLayout><AssociationMeetingsPage /></BaseLayout>} />
                    <Route path=":associationId/news" element={<BaseLayout><AssociationNewsPage /></BaseLayout>} />

                    <Route path="admin">
                        <Route path="" element={<BaseLayout><AssociationAdminDashboardPage /></BaseLayout>} />
                        <Route path="profile" element={<BaseLayout><AssociationAdminProfilePage /></BaseLayout>} />
                        <Route path="members" element={<BaseLayout><MembersListPage/></BaseLayout>} />
                        <Route path="applications" element={<BaseLayout><AssociationAdminApplicationsPage/></BaseLayout>} />
                        <Route path="meetings" element={<BaseLayout><MeetingsListPage/></BaseLayout>} />
                        <Route path="forum" element={<BaseLayout><AssociationAdminForumPage/></BaseLayout>} />
                        <Route path="news" element={<BaseLayout><AssociationAdminNewsPage/></BaseLayout>} />
                    </Route>
                </Route>
            </Route>
            <Route path="home" element={<WebBaseLayout><HomePage/></WebBaseLayout>} />
        </Route>
    </Routes>
  );
};

export default AppRoutes;
