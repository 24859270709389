import { useEffect, useState } from "react";
import axiosInstance from "../../../hooks/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../utilities/Loader/Loader";

export default function FamilyDetail({childrenDetail, spouseDetail, nextOfKinDetails, fetchUserProfile}) {
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({
    dob: '',
    phone: ''
  })
  const [children, setChildren] = useState([
    {
      child_name: '', child_dob: '', child_sex: ''
    }
  ])
  const [nextOfKin, setNextOfKin] = useState([
    {
      next_of_kin_phone: ''
    }
  ])

  const handleChange = (e) => {
    const { name, value } = e.target

    setFormData(prevFormData => (
      { ...prevFormData, [name]: value }
    ))
  }

  //  listen for changes on children settings form inputs and store in state
  const handleChildrenChange = (index, e) => {
    const { name, value } = e.target

    setChildren(prevChild => prevChild.map((t, i) => (
      i === index ? { ...t, [name]: value } : t
    )))
  }

  //  listen for changes on next of kin settings form inputs and store in state
  const handleNextOfKinChange = (index, e) => {
    const { name, value } = e.target

    setNextOfKin(prevNextOfKin => prevNextOfKin.map((t, i) => (
      i === index ? { ...t, [name]: value } : t
    )))
  }

  //    function to add children
  const addChildren = () => {
    const childrenObject = {
      child_name: '', child_dob: '', child_sex: ''
    }

    setChildren(prevChildren => (
      [...prevChildren, childrenObject]
    ))
  }

  //    function to add children
  const addNextOfKin = (type) => {
    const nextofkinObject = {
      next_of_kin_phone: ''
    }

    setNextOfKin(prevNextOfKin => (
      [...prevNextOfKin, nextofkinObject]
    ))
  }

  //    function to delete children
  const handleRemoveChildren = (i) => {
    setChildren(prevChild => (
      prevChild.filter((t, idx) => i != idx))
    )
  }

  //    function to delete next of kin
  const handleRemoveNextOfKin = (i) => {
    setNextOfKin(prevNextOfKin => (
      prevNextOfKin.filter((t, idx) => i != idx))
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    formData.children = children
    formData.next_of_kin = nextOfKin
    axiosInstance.post('/user/profile/family', formData)
              .then(res => {
                toast.success(res.data.message)
                fetchUserProfile()
              })
              .catch(err => {
                toast.error('An error occured. Please, try again')
              })
              .finally(() => setLoading(false))
  }

  useEffect(() => {
    if(spouseDetail) {
      setFormData({
        dob: spouseDetail.dob,
        phone: spouseDetail.phone
      })
    }

    if(childrenDetail) {
      const childArray = []
      childrenDetail.map((child, i) => {
        childArray.push(
          {
            child_name: childrenDetail[i].name, 
            child_dob: childrenDetail[i].dob, 
            child_sex: childrenDetail[i].sex
          }
        )
      })
      setChildren(childArray)
    }

    if(nextOfKinDetails) {
      const nextOfKinArray = []
      nextOfKinDetails.map((kin, i) => {
        nextOfKinArray.push(
          {
            next_of_kin_phone: nextOfKinDetails[i].phone,
          }
        )
      })
      setNextOfKin(nextOfKinArray)
    }

  }, [childrenDetail, spouseDetail, nextOfKinDetails])

  return (
    <form onSubmit={handleSubmit}>
      <div id="newlink">
        <div id="1">
          <h6>SPOUSE </h6>
          <div className="row border-top mt-1">
            {/* <div className="col-lg-12 mt-3">
              <div className="mb-3">
                <label htmlFor="fullname" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  placeholder="Job title"
                  value="Lead Designer / Developer"
                />
              </div>
            </div> */}
            {/* <!--end col--> */}

            <div className="col-lg-6 mt-3">
              <div className="mb-3">
                <label htmlFor="dob" className="form-label">
                  Date Of Birth
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="dob"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-6 mt-3">
              <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Enter your phone number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          {/* <!--end row--> */}
          <div className="bg-light mt-4">
            <div className="d-flex p-2">
              <h6 className="mt-3">CHILDREN </h6>
              <button
                type="button"
                className="btn btn-primary mx-3"
                onClick={(e) => addChildren()}
                disabled={children.length === 6}
              >
                {children.length === 6
                  ? "You Can Only add 6 Children"
                  : "Add New"}
              </button>
            </div>
            <div className="px-2">
              {children && children.map((child, i) => (
                <div className="row border-top mt-1" key={i}>
                  <div className="col-lg-12 mt-3">
                    <div className="mb-3">
                      <label
                        htmlFor={`child-fullname-${i}`}
                        className="form-label"
                      >
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id={`child-fullname-${i}`}
                        placeholder=" Enter Fullname"
                        name="child_name"
                        value={child.child_name}
                        onChange={(e) => handleChildrenChange(i, e)}
                      />
                    </div>
                  </div>
                  {/* <!--end col--> */}

                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor={`dob-${i}`} className="form-label">
                        Date Of Birth
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id={`dob-${i}`}
                        name="child_dob"
                        value={child.child_dob}
                        onChange={(e) => handleChildrenChange(i, e)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <label htmlFor={`sex-${i}`} className="form-label">
                        Sex
                      </label>
                      <select
                        className="form-control"
                        name="child_sex"
                        value={child.child_sex}
                        onChange={(e) => handleChildrenChange(i, e)}
                      >
                        <option value="">--Select--</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>

                    <div className="hstack gap-2 justify-content-end">
                      {i > 0 && (
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={(e) => handleRemoveChildren(i)}
                        >
                          Delete
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>

          {/* <!--end row--> */}
          {/* <!--end row--> */}
          <div className="d-flex mt-4">
            <h6 className="mt-3">NEXT OF KIN </h6>
            <button
              type="button"
              className="btn btn-primary mx-3 btn-info"
              onClick={(e) => addNextOfKin()}
              disabled={nextOfKin.length === 2}
            >
              {nextOfKin.length === 2
                ? "You Can Only add 2 Next Of kin"
                : "Add New"}
            </button>
          </div>
          <div>
            {nextOfKin && nextOfKin.map((nextOfKin, idx) => (
              <div className="row border-top mt-1" key={idx}>
                {/* <div className="col-lg-6 mt-3">
                  <div className="mb-3">
                    <label htmlFor="fullname" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullname"
                      placeholder="Enter Next Of Kin Fullname"
                      name=""
                    />
                  </div>
                </div> */}
                {/* <!--end col--> */}

                <div className="col-lg-12 mt-3">
                  <div className="mb-3">
                    <label htmlFor="phonenumberInput2" className="form-label">
                      Phone Number
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="phonenumberInput2"
                      placeholder="Enter your next of kin phone number"
                      name="next_of_kin_phone"
                      value={nextOfKin.next_of_kin_phone}
                      onChange={(e) => handleNextOfKinChange(idx, e)}
                    />
                  </div>
                  <div className="hstack gap-2 justify-content-end">
                    {idx > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={(e) => handleRemoveNextOfKin(idx)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-12 mt-4">
            <div className="hstack gap-2 justify-content-end">
              <button type="submit" className="btn btn-primary" >
                Update
              </button>
              {/* <button type="button" className="btn btn-soft-success">
              Cancel
            </button> */}
            </div>
          </div>

          {/* <!--end row--> */}
        </div>
      </div>
      {loading && <Loader />}
    </form>
  );
}
